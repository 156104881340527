import { UtilsService } from './../../../../services/utils.service';
import { LoanService } from './../../../../services/loan.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Loan } from '../../../../models/loan.model';
import { ErrorResponse } from '../../../../models/error.model';

@Component({
    selector: 'app-request-loan-account-step',
    templateUrl: './request-loan-account-step.component.html',
    styleUrls: ['./request-loan-account-step.component.scss']
})
export class RequestLoanAccountStepComponent implements OnInit {

    loanValue?: Loan;
    @Input()
    set loan(loan: Loan) {
        // TODO Completar form
        if (!loan || loan.state !== 'bank_account_info') {
            this.accountFG.disable();
        } else {
            this.accountFG.enable();
        }
        this.loanValue = loan;
    }
    get loan() {
        return this.loanValue;
    }

    @Output()
    loanChange = new EventEmitter<Loan>();

    @Output()
    next = new EventEmitter<void>();

    accountFG = new FormGroup({
        cbuOrAlias: new FormControl('cbu', [Validators.required]),
        account: new FormControl('', [Validators.required, Validators.minLength(22), Validators.maxLength(22), this.utilsService.numberFormValidator])
    });

    constructor(
        private loanService: LoanService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.accountFG.controls.cbuOrAlias.valueChanges.subscribe(newValue => {
            if (newValue === 'alias') {
                this.accountFG.controls.account.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(new RegExp(/\b[\w.-]{6,20}$/))]);
            } else {
                this.accountFG.controls.account.setValidators([Validators.required, Validators.minLength(22), Validators.maxLength(22), Validators.pattern(new RegExp(/\b\d{22}$/)), this.utilsService.numberFormValidator]);
            }
            this.accountFG.controls.account.updateValueAndValidity();
        });
    }

    continue() {
        if (this.accountFG.valid) {
            this.loanService.sendAccountInformation(this.loan.id, this.accountFG.value.account).subscribe(loan => {
                this.loanChange.emit(loan);
                this.next.emit();
            }, (error: ErrorResponse) => this.utilsService.alert('Error al enviar información de cuenta', error.error?.message));
        }
    }
}
