import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Loan } from '../../../../models/loan.model';

@Component({
    selector: 'app-request-loan-payment-subscription-step',
    templateUrl: './request-loan-payment-subscription-step.component.html',
    styleUrls: ['./request-loan-payment-subscription-step.component.scss']
})
export class RequestLoanPaymentSubscriptionStepComponent implements OnInit {

    disabled = false;

    loanValue?: Loan;
    @Input()
    set loan(loan: Loan) {
        if (loan.state !== 'payment_subscription') {
            this.disabled = true;
        } else {
            this.disabled = false;
        }
        this.loanValue = loan;
    }
    get loan() {
        return this.loanValue;
    }

    @Output()
    loanChange = new EventEmitter<Loan>();

    @Output()
    next = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

}
