
<div class="form-card payment-subscription-step">
    <span class="card-title">
        Subscripción de pago
    </span>
    <span class="card-description">
        Para realizar los pagos debe suscribirse a través de su cuenta de Mercado Pago con el siguiente botón.
    </span>
    <a class="subscribe-button" [href]="loan.paymentSubscription.initPoint" *ngIf="loan?.paymentSubscription?.initPoint">
        <button mat-raised-button class="win2win-button" [disabled]="disabled">
            Ir a Mercado Pago
        </button>
    </a>
</div>
