import { UtilsService } from './../../../services/utils.service';
import { ErrorResponse } from './../../../models/error.model';
import { LoanService } from './../../../services/loan.service';
import { Loan } from '../../../models/loan.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-request-loan',
    templateUrl: './request-loan.component.html',
    styleUrls: ['./request-loan.component.scss']
})
export class RequestLoanComponent implements OnInit {

    loan?: Loan;
    stepCompleted: boolean[] = [ false, false, false, false, false, false ]; // Actualizar cuando vayan agregando steps

    @ViewChild('stepper')
    stepper: MatStepper;

    constructor(
        private route: ActivatedRoute,
        private loanService: LoanService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params.id) {
                this.loanService.getLoanById(params.id).subscribe(loan => {
                    this.loan = loan;
                    this.goToStep(this.detectCurrentStep());
                }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener crédito', error.error?.message));
            }
        });
    }

    detectCurrentStep() {
        let step = 0;
        if (this.loan?.state === 'documentation') {
            step = 1;
        } else if (this.loan?.state === 'plan') {
            step = 2;
        } else if (this.loan?.state === 'revision') {
            step = 3;
        } else if (this.loan?.state === 'bank_account_info') {
            step = 3;
        } else if (this.loan?.state === 'signature') {
            step = 4;
        } else if (this.loan?.state === 'payment_subscription') {
            step = 5;
        } else {
            step = 6;
        }
        return step;
    }

    nextStep() {
        this.goToStep(this.stepper.selectedIndex + 1);
    }

    goToStep(stepIndex: number) {
        if (this.loan?.state === 'revision') {
            this.stepCompleted = [ false, false, false, false, false, false, false ];
        } else {
            this.stepCompleted = [ false, false, false, false, false, false ];
        }
        for (let x = 0; x < stepIndex; x++) {
            this.stepCompleted[x] = true;
        }
        this.stepper.linear = false;
        this.stepper.selectedIndex = stepIndex;
        setTimeout(() => this.stepper.linear = true);
    }
}
