import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode, SortDirection, SortType, TableColumn } from '@swimlane/ngx-datatable';
import { PageEvent } from '@angular/material/paginator';

export interface DataTableColumn extends TableColumn {
    parseValue?: (value: any, column: DataTableColumn) => string;
    template?: TemplateRef<any>;
}

export interface MenuButton<T> {
    label: string;
    action: (row: T) => void;
    isDisabled?: (row: T) => boolean;
}

export interface PaginatorStatus {
    page: number;
    itemsPerPage: number;
    totalElementCount?: number;
}

export interface SortConfiguration {
    dir?: SortDirection;
    prop: string;
}

export interface SortEvent {
    column: DataTableColumn;
    newValue?: SortType;
    prevValue?: SortType;
    sorts: SortConfiguration[];
}

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent<T> implements OnInit {

    @ViewChild('menu', {static: false})
    menu: any;

    @Input()
    set rows(rows: T[]) {
        this.originalRows = rows;
        if (this.isEnabledLocalPagination()) {
            this.paginatorStatus.totalElementCount = rows.length;
            this.updateRowsWithLocalPagination();
        }
    }
    get rows(): T[] {
        if (this.isEnabledLocalPagination()) {
            return this.paginatedRows;
        } else {
            return this.originalRows;
        }
    }

    @Input()
    columns: DataTableColumn[] = [];

    @Input()
    menuButtons: MenuButton<T>[] = [];

    @Input()
    paginatorStatus: PaginatorStatus;

    @Input()
    externalPagination = false;

    @Output()
    updateRows = new EventEmitter<PageEvent>();

    @Output()
    sortFn = new EventEmitter<SortEvent>();

    ColumnMode = ColumnMode;

    activeMenuRow?: any;

    paginatedRows: any[];

    originalRows: any[];

    constructor() { }

    ngOnInit() {
        if (this.isEnabledLocalPagination()) {
            this.paginatorStatus.totalElementCount = this.originalRows.length;
            this.updateRowsWithLocalPagination();
        }
    }

    callUpdateRows(event: PageEvent) {
        if (this.isEnabledLocalPagination()) {
            this.paginatorStatus.itemsPerPage = event.pageSize;
            this.paginatorStatus.page = event.pageIndex;
            this.updateRowsWithLocalPagination();
        } else {
            this.updateRows.emit(event);
        }
    }

    updateRowsWithLocalPagination() {
        this.paginatedRows = this.originalRows.slice(this.paginatorStatus.itemsPerPage * this.paginatorStatus.page, this.paginatorStatus.itemsPerPage * (this.paginatorStatus.page + 1));
    }

    isEnabledLocalPagination(): boolean {
        return !!this.paginatorStatus && !this.externalPagination;
    }
}
