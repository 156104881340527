<app-unlogged-navbar [leftButtons]="navbarLeftButtons" [rightButtons]="navbarRightButtons" [withBackground]="true">
    <div class="send-recovery-email">
        <h1 class="title">
            Recuperación de cuenta
        </h1>
        <form class="card-container" [formGroup]="sendRecoveryFG" (submit)="sendRecoveryEmail()">
            <div class="card">
                <h2 class="card-title">Ingresá tus datos</h2>
                <span class="card-description">Para poder recuperar tu cuenta ingresa tu email, si es correcto enviaremos un email con un link para crear tu nueva contraseña.</span>
                <span class="error-message">{{ error }}</span>

                <div class="inputs-container">
                    <mat-form-field appearance="outline" class="light-input win2win-form-field">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email">
                        <mat-error *ngIf="sendRecoveryFG.controls.email.hasError('required')">
                            Por favor complete su email
                        </mat-error>
                        <mat-error *ngIf="sendRecoveryFG.controls.email.hasError('email')">
                            Por favor ingrese un email válido
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="buttons">
                    <button mat-raised-button class="next win2win-button mini">
                        Enviar
                    </button>
                </div>
            </div>
        </form>
        <re-captcha #captchaRef="reCaptcha" (resolved)="resolvedCaptcha($event)" [siteKey]="captchaKey" size="invisible"></re-captcha>

    </div>
</app-unlogged-navbar>
