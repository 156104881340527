import { UtilsService } from './../../services/utils.service';
import { DataTableColumn } from './../../components/generics/data-table/data-table.component';
import { Fee } from './../../models/plan.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-fees-list-dialog',
    templateUrl: './fees-list-dialog.component.html',
    styleUrls: ['./fees-list-dialog.component.scss']
})
export class FeesListDialogComponent implements OnInit {

    columns: DataTableColumn[] = [
      { name: 'Nro de cuota', prop: 'feeNumber', parseValue: value => value },
      { name: 'Fecha de vencimiento', prop: 'dueDate', parseValue: value => this.utilsService.beautifyDate(value, 'L') },
      { name: 'Valor de cuota', prop: 'pureFee', parseValue: value => '$' + this.utilsService.beautifyNumber(value, 2) },
      { name: 'Interés de la cuota', prop: 'interests', parseValue: value => '$' + this.utilsService.beautifyNumber(value, 2) },
      { name: 'Impuestos', prop: 'taxes', parseValue: value => '$' + this.utilsService.beautifyNumber(value, 2) },
      { name: 'Gastos administrativos', prop: 'expenses', parseValue: value => '$' + this.utilsService.beautifyNumber(value, 2) },
      { name: 'Total a pagar', prop: 'totalFee', parseValue: value => '$' + this.utilsService.beautifyNumber(value, 2) },
      { name: 'Estado', prop: 'state', parseValue: value => value.toUpperCase() },
    ];

    constructor(
        public dialogRef: MatDialogRef<FeesListDialogComponent>,
        private utilsService: UtilsService,
        @Inject(MAT_DIALOG_DATA) public data: { fees: Fee[] }
    ) { }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

}
