<ng-container [ngSwitch]="logged">
    <ng-container *ngSwitchCase="true">
        <app-logged-navbar>
            <ng-container [ngTemplateOutlet]="pageContent"></ng-container>
        </app-logged-navbar>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <app-unlogged-navbar [leftButtons]="navbarLeftButtons" [rightButtons]="navbarRightButtons" [withBackground]="false">
            <ng-container [ngTemplateOutlet]="pageContent"></ng-container>
        </app-unlogged-navbar>
    </ng-container>
</ng-container>

<ng-template #pageContent>
    <div class="information-layout">
        <h1 class="title">{{ title }}</h1>
        <div class="content">
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>