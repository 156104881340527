
<form class="form-card documentation-step" [formGroup]="documentationFG" (submit)="sendDocumentation()">
    <span class="card-title">
        Documentación
    </span>
    <span class="card-description">
        Por favor adjunte la documentación solicitada.
    </span>
    <div class="file-input-field" [ngClass]="{ error: documentationFG.controls.idFront.hasError('required') && (documentationFG.controls.idFront.dirty || documentationFG.controls.idFront.touched) }">
        <span class="file-input-label">
            Frente del DNI
        </span>
        <input class="file-input" id="idFront-file-input" type="file" (change)="updateDocumentValue('idFront', $event)" [ngStyle]="{ display: documentationFG.controls.idFront.value ? 'none' : 'inherit' }">
        <span class="file-name" *ngIf="documentationFG.controls.idFront.value">
            {{ documentationFG.controls.idFront.value.name }}
        </span>
        <mat-error>
            Por favor ingrese el frente de su DNI
        </mat-error>
        <div class="suffix">
            <button mat-icon-button class="camera-button" color="primary" [disabled]="documentationFG.disabled" (click)="openCameraModal('idFront')" *ngIf="!documentationFG.controls.idFront.value">
                <mat-icon>photo_camera</mat-icon>
            </button>
            <button mat-icon-button class="delete-button" color="accent" [disabled]="documentationFG.disabled" (click)="documentationFG.controls.idFront.setValue(undefined)" *ngIf="documentationFG.controls.idFront.value">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="file-input-field" [ngClass]="{ error: documentationFG.controls.idBack.hasError('required') && (documentationFG.controls.idBack.dirty || documentationFG.controls.idBack.touched) }">
        <span class="file-input-label">
            Dorso del DNI
        </span>
        <input class="file-input" id="idBack-file-input" type="file" (change)="updateDocumentValue('idBack', $event)" [ngStyle]="{ display: documentationFG.controls.idBack.value ? 'none' : 'inherit' }">
        <span class="file-name" *ngIf="documentationFG.controls.idBack.value">
            {{ documentationFG.controls.idBack.value.name }}
        </span>
        <mat-error>
            Por favor ingrese el dorso de su DNI
        </mat-error>
        <div class="suffix">
            <button mat-icon-button class="camera-button" color="primary" [disabled]="documentationFG.disabled" (click)="openCameraModal('idBack')" *ngIf="!documentationFG.controls.idBack.value">
                <mat-icon>photo_camera</mat-icon>
            </button>
            <button mat-icon-button class="delete-button" color="accent" [disabled]="documentationFG.disabled" (click)="documentationFG.controls.idBack.setValue(undefined)" *ngIf="documentationFG.controls.idBack.value">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <mat-form-field class="light-input win2win-form-field" appearance="outline">
        <mat-label>Tipo de ingresos</mat-label>
        <mat-select formControlName="incomeType">
            <mat-option *ngFor="let incomeType of incomeTypes" [value]="incomeType.code">
                {{ incomeType.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="file-input-field" [ngClass]="{ error: documentationFG.controls.proofOfFounds.hasError('required') && (documentationFG.controls.proofOfFounds.dirty || documentationFG.controls.proofOfFounds.touched) }">
        <span class="file-input-label">
            {{ documentationFG.value.incomeType === 'AUTONOMO' ? 'Factura RI' : documentationFG.value.incomeType === 'MONOTRIBUTISTA' ? 'Factura del monotributo' : 'Recibo de sueldo' }}
        </span>
        <input class="file-input" id="proofOfFounds-file-input" type="file" (change)="updateDocumentValue('proofOfFounds', $event)" [ngStyle]="{ display: documentationFG.controls.proofOfFounds.value ? 'none' : 'inherit' }">
        <span class="file-name" *ngIf="documentationFG.controls.proofOfFounds.value">
            {{ documentationFG.controls.proofOfFounds.value.name }}
        </span>
        <mat-error>
            Por favor ingrese su {{ documentationFG.value.incomeType === 'AUTONOMO' ? 'factura RI' : documentationFG.value.incomeType === 'MONOTRIBUTISTA' ? 'factura del monotributo' : 'recibo de sueldo' }}
        </mat-error>
        <div class="suffix">
            <button mat-icon-button class="camera-button" color="primary" [disabled]="documentationFG.disabled" (click)="openCameraModal('proofOfFounds')" *ngIf="!documentationFG.controls.proofOfFounds.value">
                <mat-icon>photo_camera</mat-icon>
            </button>
            <button mat-icon-button class="delete-button" color="accent" [disabled]="documentationFG.disabled" (click)="documentationFG.controls.proofOfFounds.setValue(undefined)" *ngIf="documentationFG.controls.proofOfFounds.value">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="file-input-field" [ngClass]="{ error: documentationFG.controls.selfie.hasError('required') && (documentationFG.controls.selfie.dirty || documentationFG.controls.selfie.touched) }">
        <span class="file-input-label">
            Selfie con dni ( <span class="link" (click)="openHintModal()">Mas detalles</span> )
        </span>
        <input class="file-input" id="selfie-file-input" type="file" (change)="updateDocumentValue('selfie', $event)" [ngStyle]="{ display: documentationFG.controls.selfie.value ? 'none' : 'inherit' }" capture>
        <span class="file-name" *ngIf="documentationFG.controls.selfie.value">
            {{ documentationFG.controls.selfie.value.name }}
        </span>
        <mat-error>
            Por favor ingrese una foto de su rostro
        </mat-error>
        <div class="suffix">
            <button mat-icon-button class="camera-button" color="primary" [disabled]="documentationFG.disabled" (click)="openCameraModal('selfie')" *ngIf="!documentationFG.controls.selfie.value">
                <mat-icon>photo_camera</mat-icon>
            </button>
            <button mat-icon-button class="delete-button" color="accent" [disabled]="documentationFG.disabled" (click)="documentationFG.controls.selfie.setValue(undefined)" *ngIf="documentationFG.controls.selfie.value">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div class="buttons">
        <button mat-raised-button class="continue-button win2win-button" [disabled]="documentationFG.disabled">
            Continuar
        </button>
    </div>
</form>
