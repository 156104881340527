import { PageEvent } from '@angular/material/paginator';
import { PaginatorStatus } from './../../generics/data-table/data-table.component';
import { Notification } from './../../../models/notification.model';
import { UtilsService } from './../../../services/utils.service';
import { NotificationService } from './../../../services/notification.service';
import { Component, OnInit } from '@angular/core';
import { ErrorResponse } from '../../../models/error.model';

@Component({
    selector: 'app-notifications-list',
    templateUrl: './notifications-list.component.html',
    styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

    notifications: Notification[];

    paginatorStatus: PaginatorStatus = {
        itemsPerPage: 5,
        page: 0,
        totalElementCount: 0
    };

    constructor(
        private notificationService: NotificationService,
        public utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.getNotifications();
    }

    getNotifications() {
        this.notificationService.getNotifications(this.paginatorStatus.page, this.paginatorStatus.itemsPerPage, {}).subscribe(response => {
            this.notifications = response.body;
            this.paginatorStatus.totalElementCount = parseInt(response.headers.get('pagination-total-count') ?? '0', 10);
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener notificaciones', error.error?.message));
    }

    updateNotifications(event: PageEvent) {
        this.paginatorStatus.itemsPerPage = event.pageSize;
        this.paginatorStatus.page = event.pageIndex;
        this.getNotifications();
    }

}
