import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WebcamImage } from 'ngx-webcam';

@Component({
    selector: 'app-camera-dialog',
    templateUrl: './camera-dialog.component.html',
    styleUrls: ['./camera-dialog.component.scss']
})
export class CameraDialogComponent implements OnInit {

    error: string;
    snapshotEmitter = new EventEmitter<void>();
    snapshot?: string;
    maxWidth = 640;

    constructor(
        public dialogRef: MatDialogRef<CameraDialogComponent>,
    ) { }

    ngOnInit(): void {
        setTimeout(() => {
            const webcamContainer = document.getElementById('webcam-container');
            this.maxWidth = webcamContainer?.offsetWidth ?? 640;
        });
    }

    onError(event: any) {
        this.error = event.message;
    }

    onCapture(event: WebcamImage) {
        this.snapshot = event.imageAsDataUrl;
    }

    close(response?: string) {
        this.dialogRef.close(response);
    }
}
