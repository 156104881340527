
<form class="form-card account-step" [formGroup]="accountFG" (submit)="continue()">
    <span class="card-title">
        Datos bancarios
    </span>
    <span class="card-description">
        Por favor ingrese los datos de su cuenta para poder depositar su crédito.
    </span>
    <mat-radio-group formControlName="cbuOrAlias" class="cbu-or-alias-selector">
        <mat-radio-button value="cbu">CBU</mat-radio-button>
        <mat-radio-button value="alias">Alias</mat-radio-button>
    </mat-radio-group>
    <mat-form-field appearance="outline" class="light-input win2win-form-field">
        <mat-label>{{ accountFG.value.cbuOrAlias === 'cbu' ? 'CBU' : 'Alias' }}</mat-label>
        <input matInput formControlName="account">
        <mat-error *ngIf="accountFG.controls.account.hasError('required')">
            Por favor ingrese su {{ accountFG.value.cbuOrAlias === 'cbu' ? 'CBU' : 'alias' }}
        </mat-error>
        <mat-error *ngIf="accountFG.controls.cbuOrAlias.value !== 'alias' && (accountFG.controls.account.hasError('minlength') || accountFG.controls.account.hasError('maxlength'))">
            El CBU debe tener 22 dígitos
        </mat-error>
        <mat-error *ngIf="accountFG.controls.cbuOrAlias.value === 'alias' && (accountFG.controls.account.hasError('minlength') || accountFG.controls.account.hasError('maxlength'))">
            El Alias debe tener de 6 a 20 dígitos
        </mat-error>
        <mat-error *ngIf="accountFG.controls.account.hasError('pattern')">
            Se ingresaron caracteres invalidos
        </mat-error>
        <mat-error *ngIf="accountFG.controls.account.hasError('number')">
            El CBU debe ser un número
        </mat-error>
    </mat-form-field>
    <div class="buttons">
        <button mat-raised-button class="continue-button win2win-button" [disabled]="accountFG.disabled">
            Continuar
        </button>
    </div>
</form>
