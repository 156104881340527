<div class="page-container" [ngClass]="{ 'with-background': withBackground }">
    <div class="unlogged-navbar">
        <div class="left">
           <img class="logo" src="/assets/image/logo/logo.png" alt="" routerLink="/home">
           <ng-container *ngIf="!utilsService.isMobile()">
                <ng-container *ngFor="let leftButton of leftButtons">
                    <button mat-raised-button *ngIf="leftButton.raised" class="left-button" [ngClass]="{ 'win2win-button': leftButton.raised }" (click)="leftButton.onClick()">
                        {{ leftButton.label }}
                    </button>
                    <button mat-button *ngIf="!leftButton.raised" class="left-button" (click)="leftButton.onClick()">
                        {{ leftButton.label }}
                    </button>
                </ng-container>
           </ng-container>
        </div>
        <div class="right">
            <ng-container *ngIf="!utilsService.isMobile()">
                <ng-container *ngFor="let rightButton of rightButtons">
                     <button mat-raised-button *ngIf="rightButton.raised" class="left-button" [ngClass]="{ 'win2win-button': rightButton.raised }" (click)="rightButton.onClick()">
                         {{ rightButton.label }}
                     </button>
                     <button mat-button *ngIf="!rightButton.raised" class="left-button" (click)="rightButton.onClick()">
                         {{ rightButton.label }}
                     </button>
                </ng-container>
            </ng-container>
            <button mat-icon-button *ngIf="utilsService.isMobile()" class="menu-button" (click)="menuOpen = !menuOpen">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </div>
    <div class="page-content">
        <ng-content></ng-content>
    </div>
    <app-footer></app-footer>
</div>
<div class="side-menu-overlay" [ngClass]="{ open: menuOpen }" (click)="menuOpen = false"></div>
<div class="side-menu" [ngClass]="{ open: menuOpen }" *ngIf="utilsService.isMobile()">
    <button mat-icon-button class="menu-icon-button" (click)="menuOpen = !menuOpen">
        <mat-icon>menu</mat-icon>
    </button>
    <ng-container *ngFor="let menuButton of getMenuButtons()">
         <span class="button link" (click)="menuButton.onClick()">
             {{ menuButton.label }}
         </span>
    </ng-container>
</div>
