import { Plan } from './../models/plan.model';
import { Loan, IncomeType } from './../models/loan.model';
import { ID } from './../models/id.model';
import { UtilsService } from './utils.service';
import { Sex } from './../models/sex.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Document, DocumentType } from '../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class LoanService {

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService
    ) { }

    getLoans() {
        return this.http.get<Loan[]>(`/loans`);
    }

    getLoanById(id: ID) {
        return this.http.get<Loan>(`/loans/${id}`);
    }

    createLoan(identityCard: string, gender: Sex) {
        return this.http.post<Loan>(`/loans`, { identityCard, gender: gender.toLowerCase() });
    }

    uploadDocument(loanId: ID, file: File, fileType: DocumentType) {
        return this.utilsService.uploadFile<Document>(file, `/loans/${loanId}/documents/url`, `/loans/${loanId}/documents`, {}, { type: fileType });
    }

    setIncomeType(id: ID, incomeType: IncomeType) {
        return this.http.post<Loan>(`/loans/${id}/income-type`, { incomeType });
    }

    getPlan(id: ID) {
        return this.http.get<Plan>(`/loans/${id}/plan`);
    }

    getPlanSimulation(id: ID, selectedAmount: number, selectedTerm: number) {
        return this.http.post<Plan>(`/loans/${id}/plan/simulation`, { selectedAmount, selectedTerm });
    }

    confirmLoan(id: ID, selectedAmount: number, selectedTerm: number) {
        return this.http.post<Loan>(`/loans/${id}/confirmation`, { selectedAmount, selectedTerm });
    }

    sendAccountInformation(loanId: ID, account: string) {
        return this.http.post<Loan>(`/loans/${loanId}/account-information`, { account });
    }

    sendSignature(loanId: ID, documentId: string) {
        return this.http.post<Loan>(`/loans/${loanId}/signature`, { documentId });
    }
}
