import { InformationService } from 'src/app/services/information.service';
import { UtilsService } from './../../services/utils.service';
import { Router } from '@angular/router';
import { NavbarButton } from './../navbar/unlogged-navbar/unlogged-navbar.component';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface CarouselSlide {
    image: string;
    title: string;
    description: string;
    buttonText?: string;
    onClick?: () => void;
}

interface Step {
    icon: string;
    title: string;
    description: string;
}

export interface FAQ {
    question: string;
    answer: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

    navbarLeftButtons: NavbarButton[] = [
        { label: 'Productos', onClick: () => this.products.nativeElement.scrollIntoView({ behavior: 'smooth' }) },
        { label: 'Preguntas frecuentes', onClick: () => this.router.navigate(['faq']) },
        { label: 'Mi cuenta', onClick: () => this.router.navigate(['session', 'login']) }
    ];

    navbarRightButtons: NavbarButton[] = [];

    slides: CarouselSlide[] = [
        { image: '/assets/image/home/carousel/slide-1.jpg', title: 'Créditos personales', description: '100% online, con plazos y tasas a tu medida.', buttonText: 'Pedí el tuyo', onClick: () => this.router.navigate(['session', 'create-account']) },
        { image: '/assets/image/home/carousel/slide-2.jpg', title: 'Créditos personales', description: '100% online, con plazos y tasas a tu medida.', buttonText: 'Pedí el tuyo', onClick: () => this.router.navigate(['session', 'create-account']) },
        { image: '/assets/image/home/carousel/slide-3.jpg', title: 'Créditos personales', description: '100% online, con plazos y tasas a tu medida.', buttonText: 'Pedí el tuyo', onClick: () => this.router.navigate(['session', 'create-account']) },
    ]; // TODO Completar

    steps: Step[] = [
        { icon: '/assets/image/home/steps/step-1.svg', title: 'Crea tu cuenta', description: 'Solo te pediremos 2 datos email CUIT/CUIL.' },
        { icon: '/assets/image/home/steps/step-2.svg', title: 'Ingresa tus datos', description: 'Completá la solicitud ingresando tus datos.' },
        { icon: '/assets/image/home/steps/step-3.svg', title: 'Cumplí tu sueño', description: 'Recibís los fondos directamente en tu cuenta sueldo.' },
    ];

    selectedFaq = 0;
    faqsTranslation = 0;
    cardsInside = 3;

    @ViewChild('products', { static: true })
    products: ElementRef;

    @ViewChild('faqsList', { static: true })
    faqsList: ElementRef;

    @ViewChild('faqsContainer', { static: true })
    faqsContainer: ElementRef;

    @ViewChildren('faq')
    faqElements: QueryList<ElementRef>;

    constructor(
        private router: Router,
        private utilsService: UtilsService,
        private informationService: InformationService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon(
            'carousel-arrow-left',
            sanitizer.bypassSecurityTrustResourceUrl('assets/image/home/carousel/arrow-left.svg')
        );
        iconRegistry.addSvgIcon(
            'carousel-arrow-right',
            sanitizer.bypassSecurityTrustResourceUrl('assets/image/home/carousel/arrow-right.svg')
        );
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.setFaq(0);
        });
    }

    previousFaq() {
        this.setFaq(Math.max(0, this.selectedFaq - 1));
    }

    nextFaq() {
        this.setFaq(Math.min(this.getFaqs().length - 1, this.selectedFaq + 1));
    }

    setFaq(index: number) {
        this.selectedFaq = index;
        this.faqsTranslation = this.getFaqsTranslation();
    }

    isHiddenFaq(index: number) {
        const firstFaqVisible = this.getFirstFaqVisible();
        return index < firstFaqVisible || index > firstFaqVisible + this.cardsInside - 1;
    }

    getFaqs() {
        return this.informationService.getHomeFaqs();
    }

    getFirstFaqVisible() {
        if (this.selectedFaq < this.cardsInside - 1) {
            return 0;
        } else if (this.selectedFaq >= this.getFaqs().length - (this.cardsInside - 1)) {
            return this.getFaqs().length - this.cardsInside;
        } else {
            if (this.cardsInside === 1) {
                return this.selectedFaq;
            } else {
                return this.selectedFaq - 1;
            }
        }
    }

    getFaqsTranslation() {
        if (this.faqsList && this.faqsContainer && this.faqElements && this.faqElements.length === this.getFaqs().length) {
            const faqElements = this.faqElements.toArray();
            const listWidth = this.faqsList.nativeElement.offsetWidth;
            if (this.utilsService.isMobile()) {
                this.cardsInside = 1;
            } else {
                this.cardsInside = 3;
            }
            const containerWidth = this.faqsContainer.nativeElement.offsetWidth;
            const cardWidth = faqElements.find(elem => !elem.nativeElement.className.includes('selected'))?.nativeElement.offsetWidth;
            const selectedCardWidth = faqElements.find(elem => elem.nativeElement.className.includes('selected'))?.nativeElement.offsetWidth;
            const cardCount = this.getFaqs().length;
            const sizeOccupedByCards = cardWidth * (cardCount - 1) + selectedCardWidth;
            const spacing = (containerWidth - sizeOccupedByCards) / (cardCount - 1);
            const paddingLeftForCentering = (listWidth - (this.cardsInside - 1) * cardWidth - selectedCardWidth - (this.cardsInside - 1) * spacing) / 2;
            let offset = 0;
            faqElements.forEach((faqElement, index) => {
                if (index < this.getFirstFaqVisible()) {
                    offset += faqElement.nativeElement.offsetWidth + spacing;
                }
            });
            return paddingLeftForCentering - offset;
        }
        return 0;
    }
}
