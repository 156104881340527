import { PersonalInformation } from './../models/personal-information.model';
import { User } from './../models/usuario.model';
import { ActivationSMSResponse } from './../models/activation-sms-response.model';
import { Phone } from './../models/phone.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Session } from '../models/session.model';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class AuthService implements CanActivate {

    private storageTokenKey = 'auth-win2win';
    private userKey = 'user-win2win';

    constructor(private http: HttpClient, private router: Router) { }

    login(email: string, password: string) {
        return this.http.post<Session>('/users/login', { email, password }, { headers: { 'x-recaptcha-hash': 'Recaptcha test' } });
    }

    getLogged(): boolean {
        return !!localStorage.getItem(this.storageTokenKey);
    }

    getToken(): string {
        return localStorage.getItem(this.storageTokenKey);
    }

    saveToken(token: string) {
        localStorage.setItem(this.storageTokenKey, token);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (route.data && route.data.requireAuth === true) {
            if (this.getLogged()) {
                return true;
            }
            this.router.navigate(['/session/login'], { queryParams: { returnUrl: state.url } });
            return false;
        } else if (route.data && route.data.requireAuth === undefined) {
            return true;
        } else {
            if (this.getLogged()) {
                this.router.navigate(['/dashboard']);
                return false;
            }
            return true;
        }
    }

    logout() {
        localStorage.removeItem(this.storageTokenKey);
    }

    saveUser(user: User) {
        localStorage.setItem(this.userKey, JSON.stringify(user));
    }

    getUser(): User {
        if (localStorage.getItem(this.userKey)) {
            return JSON.parse(localStorage.getItem(this.userKey)) as User;
        } else {
            return null;
        }
    }

    getSelfUser() {
        return this.http.get<User>(`/users/self`, { headers: { 'x-request-id': '' } });
    }

    sendActivationSms(phone: Phone, email: string, recaptchaCode: string, isATest: boolean) {
        return this.http.post<ActivationSMSResponse>(`/validation`, { phone, email, recaptchaCode, isATest }, { headers: { 'x-request-id': '' } });
    }

    createAccount(email: string, password: string, phone: Phone, secret: string, transactionCode: string, sendingType: string, mercadolibreId?: string) {
        return this.http.post<Session>(`/users`, { email, password, phone, secret, transactionCode, sendingType, mercadolibreId });
    }

    checkEmail(email: string, recaptchaCode: string) {
        return this.http.post(`/users/check/email`, { email, recaptchaCode }, { headers: { 'x-request-id': '' } });
    }

    checkPhone(phone: string, recaptchaCode: string) {
        return this.http.post(`/users/check/phone`, { phone, recaptchaCode }, { headers: { 'x-request-id': '' } });
    }

    changePassword(newPassword: string) {
        const id = this.getUser()?.id;
        return this.http.put<User>(`/users/${id}/password`, { newPassword });
    }

    updatePersonalInformation(personalInformation: PersonalInformation) {
        const id = this.getUser()?.id;
        return this.http.put<User>(`/users/${id}`, personalInformation);
    }

    sendRecoveryEmail(email: string, recaptchaCode: string) {
        return this.http.post(`/users/recovery/request`, { email, recaptchaCode });
    }

    recoverAccount(secret: string, email: string, newPassword: string) {
        return this.http.post(`/users/recovery`, { secret, email, newPassword });
    }
}
