<div class="fees-list-dialog">
    <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <span class="title">
        Lista de cuotas
    </span>
    <app-data-table
        [rows]="data.fees"
        [columns]="columns"
        class="table"
    ></app-data-table>
</div>
