<div class="sex-selector">
    <span class="label">
        Sexo
    </span>
    <div class="selector">
        <button mat-raised-button type="button" class="selector-button male" (click)="setSex('M')" [ngClass]="{'selected': sex == 'M'}" [disabled]="disabled">
            M
        </button>
        <button mat-raised-button type="button" class="selector-button female" (click)="setSex('F')" [ngClass]="{'selected': sex == 'F'}" [disabled]="disabled">
            F
        </button>
    </div>
</div>
