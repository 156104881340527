<div class="footer">
    <div class="footer-top">
        <div class="contact">
            <span class="contact-title">Contacto</span>
            <a href="mailto:info@win2win.com.ar" class="contact-mail">info@win2win.com.ar</a>
            <span class="contact-address">Av. Del Libertador Nº 7766</span>
            <span class="contact-address">Ciudad Autónoma de Buenos Aires</span>
        </div>
        <div class="social-networks">
            <div class="follow">
                <span class="social-networks-title">Seguinos en</span>
                <div class="social-networks-icons">
                    <img src="/assets/image/social-networks/facebook.png" alt="" class="social-networks-icon">
                    <img src="/assets/image/social-networks/twitter.png" alt="" class="social-networks-icon">
                    <img src="/assets/image/social-networks/instagram.png" alt="" class="social-networks-icon">
                    <img src="/assets/image/social-networks/linkedin.png" alt="" class="social-networks-icon">
                </div>
            </div>
            <img src="/assets/image/logo/logo-sin-texto.png" alt="" class="logo">
        </div>
    </div>
    <div class="footer-bottom">
        <span class="access-rights">
            Win2Win©2020 - Todos los derechos reservados.
        </span>
        <a class="faqs-link" routerLink="/faq">
            Preguntas frecuentes
        </a>
        <span class="terms-and-conditions-private-policy">
            <a routerLink="/terms-and-conditions">Términos y condiciones</a> - <a routerLink="/privacy-policy">Políticas de privacidad</a>
        </span>
    </div>
</div>
