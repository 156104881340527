<app-information-layout title="Términos y condiciones">
    <div class="terms-and-conditions">
        <p>
            Los presentes términos y condiciones (los “Términos y Condiciones”) serán aplicables al acceso y uso del sitio https://www.win2win.com.ar (el “Sitio”), titularidad de Win2Win S.A. CUIT 30-71699178-0, con domicilio en Av. Del Libertador Nº 7766, CP C1429BMX, Ciudad Autónoma de Buenos Aires (la “Empresa”), incluyendo, sin limitación, la utilización de cualquiera de los productos y/o servicios ofrecidos por la Empresa en él (los “Servicios”).
            Cualquier persona (el “Usuario”) que desee acceder al Sitio y/o utilizar cualquiera de los Servicios podrá hacerlo únicamente si acepta previamente los presentes Términos y Condiciones, junto con todas las demás políticas de la Empresa actualmente vigentes, o tal como las mismas sean modificadas y/o actualizadas por la Empresa en el futuro.
        </p>
        <span class="bold">SOLICITAMOS A UD. QUE LEA DETALLADAMENTE CADA UNO DE LOS TÉRMINOS Y CONDICIONES EXPUESTOS SEGUIDAMENTE, POR CUANTO LA UTILIZACIÓN DEL SITIO IMPLICA LA ACEPTACIÓN Y ADHESIÓN A LA TOTALIDAD DE ESTOS TÉRMINOS Y CONDICIONES. POR ELLO, ES OBLIGACIÓN Y CARGA DE TODO USUARIO DEL SITIO, LEER Y COMPRENDER TALES TÉRMINOS Y CONDICIONES PREVIO A CUALQUIER UTILIZACIÓN DEL SITIO. CUALQUIER USUARIO QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.</span>
        <br>
        <span class="bold">a) CONDICIONES DE ACCESO AL SITIO Y PARA LA PRESTACIÓN DE LOS SERVICIOS</span>
        <p>El acceso al Sitio y/o la adquisición de los Servicios se encuentra disponible únicamente para personas físicas que reúnan todos los siguientes requisitos: (i) sean mayores de dieciocho (18) años y tengan hasta sesenta y cuatro (64) años; (ii) tengan capacidad legal para contratar; (iii) se encuentren trabajando en relación de dependencia con una antigüedad mínima de cuatro (4) meses y (iv) sean titulares de una cuenta bancaria en una entidad financiera aprobada por el Banco Central de la República Argentina donde se depositen los haberes del trabajo en relación de dependencia vigente y/o los ingresos percibidos en el marco de la actividad desarrollada bajo el régimen de monotributo (“Cuenta Bancaria”).</p>
        <p>La Empresa informa que no podrán utilizar el Sitio ni contratar los Servicios las personas jurídicas, los menores de edad y/o las personas inhabilitadas para contratar, temporal o definitivamente.</p>
        <p>El Usuario declara bajo juramento que: (i) no se encuentra en cesación de pagos y no ha presentado judicialmente ninguna solicitud para la apertura de un concurso preventivo, acuerdo preventivo extrajudicial y/o quiebra, (ii) no es beneficiario de pensiones no contributivas –pensiones asistenciales, pensiones por leyes especiales, pensiones graciables, etc.–, y (iii) no se encuentra inhabilitado ni sujeto a medidas cautelares.</p>
        <p>El Usuario reconoce y acepta que la Empresa se reserve el derecho de suspender, o dar de baja, a todo Usuario sin necesidad de expresión de causa, no generando ello derecho a indemnización alguna para el Usuario.</p>
        <span class="bold">b) SOLICITUD DE ASISTENCIA FINANCIERA</span>
        <p>Para el correcto uso de los Servicios por parte del Usuario es requisito indispensable que éste suministre previamente, y con carácter de declaración jurada, los siguientes datos de registración en todos sus campos (los “Datos Personales”):
        <br>
        <span class="bold">
        <br>Documento Nacional de Identidad
        <br>Correo electrónico
        <br>Teléfono celular
        <br>Cuenta Bancaria en la cual percibe el ingreso
        <br>Situación laboral
        </span>
        <p>El Usuario garantiza la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados y asume el compromiso de informar a la Empresa cualquier novedad que se produzca respecto de ellos.</p>
        <p>El Usuario acuerda que la Empresa podrá, a su exclusivo criterio, solicitar la presentación o envío de cualquier comprobante y/o dato adicional que considere necesario a efectos de corroborar y/o confirmar y/o verificar la exactitud de los Datos Personales proporcionados por el Usuario. Por ello, la Empresa podrá enviar al Usuario un código de seguridad mediante el servicio de mensajería electrónica (SMS) a fin de validar el teléfono celular informado por el Usuario.</p>
        <p>El Usuario deberá leer detalladamente los Términos y Condiciones; el Usuario que haga “clic” o marque el recuadro que indica que “Leí y acepto los Términos y Condiciones”, aceptará todas y cada una de los obligaciones y condiciones convenidas y se compromete a utilizar el Sitio y/o los Servicios de conformidad con los presentes Términos y Condiciones. El incumplimiento por parte del Usuario de cualquiera de las obligaciones o condiciones podrá ocasionar la caducidad automática de los Servicios.</p>
        <p>La remisión de la solicitud de asistencia financiera significa que: (i) El Usuario leyó y entendió los presentes Términos y Condiciones que se encuentran en el Sitio y que se dan por reproducidos en el presente en cuanto complementan a los mismos y/o a cualquier otro tipo de comunicación respecto de los Servicios entre la Empresa y el Usuario; (ii) el Usuario aceptó los Términos y Condiciones y reconoce y acepta que las condiciones particulares que lo vincularon con la Empresa son las acordadas individualmente, (iii) es intención del Usuario que la Empresa acredite el Préstamo en la Cuenta Bancaria indicada por el Usuario, (iv) el Usuario expresamente autoriza a la Empresa y/o Épico S.A. y/o a quienes éstas designen a percibir el capital, los intereses moratorios y la penalidad por mora del mutuo mediante la operatoria de débito interno o interbancario y/o DEBIN Spot correspondiente al Sistema Nacional de Pagos (SNP), reglamentado por la comunicación “A” 2.559 de Banco Central y sus modificatorias, descontando tales importes de cualquier cuenta bancaria de su titularidad. La cancelación de los montos adeudados por el SOLICITANTE al MUTUANTE será efectuada al momento de percibir el MUTUANTE la totalidad de los importes debidos.</p>
        <p>El Usuario reconoce que es requisito imprescindible para el otorgamiento de la asistencia financiera por parte de la Empresa que se encuentre vigente el CBU informado. Por tal motivo, el Usuario acuerda que informará a la Empresa de cualquier cambio de CBU, manteniendo, en todo momento durante la vigencia de la relación contractual con la Empresa, un CBU válido y con fondos suficientes en la cuenta correspondiente a efectos del repago de toda suma debida por el Usuario a la Empresa.</p>
        <p>La Empresa podrá, a su exclusivo criterio y en cualquier momento, rechazar cualquier solicitud de asistencia financiera de manera parcial y/o total a cualquier Usuario cuyos Datos Personales no hayan podido ser confirmados, o que, al exclusivo criterio de la Empresa, no reúna los requisitos crediticios suficientes, sin que ello genere derecho a reclamo o indemnización alguna a favor del Usuario.</p>
        <p>La Empresa podrá, a su exclusivo criterio, requerir al Usuario la firma de la solicitud de mutuo como requisito para su otorgamiento. En consecuencia, la Empresa podrá requerir la digitalización de la firma ológrafa (la “Firma Digitalizada”) del Usuario; la Empresa y el Usuario acuerdan que la Firma Digitalizada tendrá los mismos efectos que la firma ológrafa y consecuentemente, reconocen la plena validez de los documentos generados mediante el uso de la Firma Digitalizada, renunciando expresamente a cuestionar su autenticidad y/o validez.</p>
        <p>La Empresa informará al Usuario un código de seguridad (el “Código de Seguridad”) y el Usuario deberá aportar una imagen de su rostro (la “Foto”) junto con el Código de Seguridad, que será utilizada por la Empresa para validar su identidad mediante el sistema de reconocimiento facial. A tal fin, la Empresa comparará los parámetros que surjan de la Foto con la imagen del documento de identidad del Usuario, que éste deberá aportar durante el mismo proceso.</p>
        <p>El contrato se celebra a distancia, quedando constancia de la oferta y de la aceptación. El consentimiento del Usuario -entendido como la manifestación de voluntad libre, inequívoca e informada- se materializará cuando el Usuario que desee solicitar asistencia financiera y que haya ingresado previamente sus Datos Personales y aceptado los presentes Términos y Condiciones, haga “click” en el botón identificado como “enviar solicitud” en la Página Web a tales efectos y/o cuando manifieste por cualquier otro medio su intención de recibir asistencia financiera.</p>
        <p>Finalizado el proceso, el Usuario recibirá en su casilla de correo electrónico un ejemplar de la solicitud de mutuo y los presentes Términos y Condiciones. No obstante lo anterior, el Usuario podrá solicitar una copia de los Términos y Condiciones y de la solicitud de mutuo respectiva mediante correo electrónico a info@win2win.com.ar indicando su nombre completo y DNI.</p>
        <p>En la solicitud de mutuo se informarán los canales de pago disponibles y los códigos personales del Usuario para efectuar los respectivos pagos. Si el Usuario no recibiera la solicitud de mutuo y/o los códigos informados estuvieran incompletos y/o fueran erróneos, igualmente deberá pagar las sumas que correspondan en los plazos fijados, para lo cual podrá solicitar los códigos mediante correo electrónico dirigido a info@win2win.com.ar y/o en forma telefónica al 0800-xxx-xxxx(indicar nro de contacto w2w).</p>
        <p>La Empresa podrá requerir la firma ológrafa de la solicitud de mutuo; en tal caso, se informará previamente al Usuario esta circunstancia.</p>
        <p>La presente solicitud de mutuo se considerará aceptada por la Empresa con el desembolso de la suma mencionada; el cumplimiento de los pasos impuestos para el proceso de solicitud de mutuo no implica necesariamente la efectiva contratación del Servicio.</p>
        <span class="bold">c) AUTORIZACIÓN DE USO DE DATOS</span>
        <p>La Empresa acuerda manipular los Datos Personales de conformidad con la legislación aplicable, incluyendo sin limitación, la ley 25.326, tal como la misma sea actualizada y/o modificada y lo dispuesto en tal sentido a continuación en los presentes Términos y Condiciones.</p>
        <p>La Empresa informa y el Usuario acepta que los Datos Personales serán incorporados a la base de datos de titularidad de la Empresa. Por medio del presente el Usuario presta su consentimiento para la recolección, ordenación, conservación, almacenamiento y modificación de sus Datos Personales en la mencionada base de datos conforme lo informado en los documentos “Derecho de Acceso” y “Actualización o Rectificación de Datos Personales” que forman parte del Sitio.</p>
        <span class="bold">EL USUARIO PRESTA SU CONSENTIMIENTO PARA LA RECOLECCIÓN, ORDENACIÓN, USO, CONSERVACIÓN, ALMACENAMIENTO Y MODIFICACIÓN DE SUS DATOS PERSONALES POR PARTE DE LA EMPRESA A LOS EFECTOS DEL ANÁLISIS CREDITICIO Y FINANCIERO DE LAS SOLICITUDES QUE REMITA, DE SU PROCESAMIENTO CON FINES COMERCIALES, ESTADÍSTICOS Y/O DE COBRANZA Y/O EL ENVÍO DE INFORMACIÓN Y/O PROMOCIONES Y/O PUBLICIDAD ASOCIADA AL SITIO Y LOS SERVICIOS DE LA SOCIEDAD O DE TERCEROS CON LOS QUE LA EMPRESA PUDIERA TENER ALGUNA RELACIÓN COMERCIAL. ASIMISMO, EL USUARIO PRESTA CONFORMIDAD CON LA CESIÓN Y/O TRANSMISIÓN DE SUS DATOS PERSONALES A TERCEROS CON LOS QUE LA EMPRESA PUDIERA TENER ALGUNA RELACIÓN COMERCIAL, Y SÓLO PARA LOS FINES ANTES REFERIDOS. FINALMENTE SE INFORMA QUE EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL DERECHO DE ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO INFERIORES A SEIS MESES, SALVO QUE SE ACREDITE UN INTERÉS LEGÍTIMO AL EFECTO CONFORME LO ESTABLECIDO EN EL ARTÍCULO 14, INCISO 3 DE LA LEY Nº 25.326. LA DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, ÓRGANO DE CONTROL DE LA LEY 25.326, TIENE LA ATRIBUCIÓN DE ATENDER LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN CON RELACIÓN AL INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN DE DATOS PERSONALES. </span>
        <br>
        <span class="bold">d) DERECHO DE REVOCACIÓN</span>
        <span class="bold">EL USUARIO PODRÁ REVOCAR LA ACEPTACIÓN DE SU SOLICITUD DE ASISTENCIA FINANCIERA DENTRO DE LOS DIEZ DÍAS CORRIDOS (O DÍA HÁBIL SIGUIENTE), CONTADOS A PARTIR DE LA ACEPTACIÓN DE LA MISMA POR PARTE DE LA EMPRESA. EL USUARIO DEBERÁ NOTIFICAR A LA EMPRESA SU DECISIÓN DE MANERA FEHACIENTE Y DEBERÁ REEMBOLSAR A LA EMPRESA TODAS LAS SUMAS QUE HAYA PERCIBIDO DENTRO DEL MENCIONADO PLAZO, EN CUYO CASO SE DEBERÁN LOS INTERESES Y GASTOS, EN CASO DE CORRESPONDER.</span>
        <br>
        <span class="bold">e) LIMITACIÓN DE RESPONSABILIDAD</span>
        La Empresa se reserva el derecho de rechazar, sin expresión de causa, cualquier solicitud de préstamo de cualquier Usuario, sin que ello genere un derecho a indemnización o resarcimiento a favor del Usuario. Asimismo, la Empresa declara, y el Usuario reconoce y acepta, que el silencio de la Empresa se entenderá como una negativa al otorgamiento de asistencia, renunciado el Usuario por el presente a presentar y/o realizar cualquier reclamo.
        <br>
        <span class="bold">f) MODIFICACIONES</span>
        La Empresa se reserva el derecho de modificar, en cualquier momento, el contenido disponible en el Sitio, arancelar o, de cualquier otra forma, limitar el acceso al mismo, y aún discontinuar sin previo aviso. La Empresa podrá, a su exclusivo criterio y de tiempo en tiempo, modificar los presentes Términos y Condiciones. En caso de hacerlo, los mismos serán vinculantes respecto del Usuario una vez que los mismos se encuentren publicados en el Sitio. Finalmente, la Empresa no garantiza la exactitud y vigencia de la información proporcionada en el Sitio, aunque sí compromete sus mejores esfuerzos en tal sentido.
        <span class="bold">g) PROHIBICIÓN DE USO</span>
        El Usuario reconoce y acepta que se abstendrá de realizar cualquier acción y/o utilizar cualquier tipo de dispositivo, software, u otro medio tendiente a interferir tanto en el Sitio como en los Servicios. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este apartado harán pasible a su responsable de las acciones legales pertinentes, incluyendo sin limitación cualquier acción por daños y perjuicios y acciones penales, junto con las sanciones previstas en los presentes Términos y Condiciones.
        El Usuario acepta que, en los casos que exista una generación de contraseña relacionada con el Sitio y/o los Servicios, deberá almacenarla adecuadamente, reconociendo que la misma es de uso personal y que, bajo ninguna circunstancia, debe comunicar ni, de cualquier manera, transferirla a terceros. Todo Usuario será responsable de los daños y perjuicios que la violación de tal deber generase a la Empresa y/o a terceros.
        <br>
        <span class="bold">h) FALLAS DEL SISTEMA</span>
        La Empresa no se responsabiliza por cualquier daño y/o perjuicio causado al Usuario relacionado con fallas en el Sitio, el sistema, en el servidor o en Internet. La Empresa tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso de su Sitio o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. El Usuario reconoce y acepta que no podrá imputar responsabilidad alguna a la Empresa, ni exigir pago por lucro cesante, en virtud de daños y/o perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. La Empresa no garantiza el acceso y uso continuado e ininterrumpido de su Sitio. El Usuario reconoce y acepta que el sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia; en tales casos la Empresa procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputarse algún tipo de responsabilidad a la Empresa.
        Consecuentemente, la Empresa no asume responsabilidad por: (i) la inviolabilidad de la transmisión de los datos cursados al Sitio por las redes públicas de telecomunicaciones, o por cualquier otro medio telemático; (ii) las eventuales consecuencias de cualquier índole resultantes de fallas en el sistema, en los servidores o en Internet que pudieran resultar del acceso y/o uso del Sitio; (iii) la veracidad de la publicidad de terceros que contenga el Sitio ni por las eventuales vinculaciones que el usuario establezca con ellos y/o con otros usuarios; (iv) la interrupción temporal o permanente, por cualquier causa que impida el acceso al Sitio; o (v) cualquier daño o perjuicio (incluyendo, a mero título ejemplificativo, lucro cesante, pérdida de información, pérdida de una chance, etc.) debidos al uso total o parcial, imposibilidad de uso total o parcial o al resultado del uso total o parcial del Sitio.
        <br>
        <span class="bold">i) PROPIEDAD INTELECTUAL</span>
        El Usuario reconoce y acepta que el Sitio, incluyendo sin limitación, los programas, bases de datos, redes, archivos y todo lo relacionado con el Sitio, son de propiedad exclusiva de la Empresa y están protegidos por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido por parte del Usuario, y/o la reproducción total y/o parcial de dichos contenidos quedan prohibidos, salvo autorización previa, expresa y por escrito de la Empresa. En caso de que la Empresa otorgue una autorización conforme lo indicado precedentemente, el Usuario reconoce y acepta que la mencionada autorización aplicará por única vez y con los efectos atribuidos a la misma por la Empresa, no pudiendo entender el Usuario que la Empresa haya otorgado otras autorizaciones.
        La Empresa manifiesta y el Usuario acepta que el Sitio puede contener enlaces a otros sitios web, lo cual no implica que sean propiedad u operados por la Empresa. En tal sentido, la Empresa no será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente. La presencia de enlaces a otros sitios web no implica una sociedad ni relación entre la Empresa y dichos sitios y sus contenidos.
        EN NINGÚN CASO LA EMPRESA, NI SUS DIRECTORES, FUNCIONARIOS, EMPLEADOS Y/O REPRESENTANTES (CONJUNTAMENTE, LAS “PERSONAS PROTEGIDAS”) SERÁN RESPONSABLES COMO RESULTADO DEL USO DEL SITIO Y/O LOS SERVICIOS Y/O CON LOS CONTENIDOS, MATERIALES Y FUNCIONES RELACIONADOS CON LOS MISMOS. EN NINGÚN CASO SERÁN RESPONSABLES LAS PERSONAS PROTEGIDAS EN RELACIÓN CON CUALQUIER CONTENIDO PUBLICADO, TRANSMITIDO, INTERCAMBIADO O RECIBIDO A TRAVÉS DEL SITIO Y/O EN RELACIÓN CON LOS SERVICIOS. EL USUARIO MANTENDRÁ PERMANENTEMENTE INDEMNE A CUALQUIER PERSONA PROTEGIDA RESPECTO DE CUALQUIER DAÑO Y/O PERJUICIO RELACIONADO CON EL USO DEL SITIO Y/O LA UTILIZACIÓN DE CUALQUIERA DE LOS SERVICIOS.
        <br>
        <span class="bold">j) CESIÓN</span>
        El Usuario presta su conformidad para que la Empresa pueda ceder fiduciariamente los derechos resultantes del uso de cualquiera de los productos y/o Servicios ofrecidos en el Sitio, sin que ello pueda implicar modificación alguna de las restantes obligaciones asumidas por el Usuario. En dicho caso y conforme a lo dispuesto en los arts. 70,71 y 72 de la ley 24.441, el Usuario reconoce y acepta que no será requisito que se le notifique dicha cesión.
        <br>
        <span class="bold">k) DOMICILIO – COMUNICACIONES</span>
        El Usuario reconoce la plena validez de las notificaciones que se envíen y/o reciban a través del Sitio y/o por correo electrónico y/o teléfono y/o en el domicilio real suministrados por el Usuario a la Empresa y acepta que las notificaciones allí enviadas serán válidas y vinculantes. Por ello, el Usuario se compromete a consultar su casilla de correo electrónico, tanto bandeja de entrada, spam y/o correo no deseado, y de mensajería electrónica (SMS, WhatsApp) regularmente y se obliga a informar a la Empresa de cualquier cambio en la dirección de su correo electrónico, línea celular y respecto de su domicilio real.
        <br>
        <span class="bold">l) JURISDICCIÓN Y LEY APLICABLE</span>
        Los presentes Términos y Condiciones se regirán por las leyes de la República Argentina. Cualquier controversia derivada de los presentes Términos y Condiciones, su existencia, validez, interpretación, alcance y/o cumplimiento, será sometida ante la Justicia Nacional Ordinaria con asiento en la Ciudad Autónoma de Buenos Aires.
        Última modificación: Febrero 2021
    </div>
</app-information-layout>
