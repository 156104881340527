import { Router } from '@angular/router';
import { NavbarButton } from './../../navbar/unlogged-navbar/unlogged-navbar.component';
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-information-layout',
  templateUrl: './information-layout.component.html',
  styleUrls: ['./information-layout.component.scss']
})
export class InformationLayoutComponent implements OnInit {

    navbarLeftButtons: NavbarButton[] = [
    ];

    navbarRightButtons: NavbarButton[] = [
        { label: 'Ingresar', onClick: () =>  this.router.navigate(['session', 'login'])  },
        { label: 'Crear cuenta', onClick: () =>  this.router.navigate(['session', 'create-account']) , raised: true }
    ];

    @Input()
    title: string;

    logged: boolean = false;

    constructor(
      private router: Router,
      private authService: AuthService
    ) { }

    ngOnInit(): void {
      this.logged = this.authService.getLogged();
    }
}
