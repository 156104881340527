
<form class="form-card signature-step" [formGroup]="signatureFG" (submit)="continue()">
    <span class="card-title">
        Firma
    </span>
    <span class="card-description">
        Por favor ingrese su firma dentro del recuadro gris, al presionar confirmar comenzará su préstamo.
    </span>
    <signature-pad class="signature-pad" [options]="signaturePadOptions"></signature-pad>
    <span class="link clear" (click)="signaturePad.clear()">
        Borrar
    </span>
    <div class="buttons">
        <button mat-raised-button class="continue-button win2win-button" [disabled]="signatureFG.disabled || signaturePad?.isEmpty()">
            Confirmar
        </button>
    </div>
</form>
