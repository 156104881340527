import { Sex } from './../../../models/sex.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output, forwardRef } from '@angular/core';

@Component({
    selector: 'app-sex-selector',
    templateUrl: './sex-selector.component.html',
    styleUrls: ['./sex-selector.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SexSelectorComponent), multi: true }
    ]
})
export class SexSelectorComponent implements OnInit, ControlValueAccessor {

    @Output() valueChange = new EventEmitter();

    @Input()
    get value() {
        return this.sex;
    }
    set value(val: Sex) {
        this.sex = val;
        this.valueChange.emit(this.sex);
        this.onChange(val);
    }

    @Input()
    disabled: boolean;

    public sex: Sex = 'M';

    onChange = (_: any) => { };
    onTouched = () => { };

    constructor() { }

    ngOnInit() {
    }

    setSex(newSex) {
        this.value = newSex;
    }

    writeValue(obj: any): void {
        if (obj !== null && obj !== undefined && (obj === 'M' || obj === 'F')) {
            this.value = obj;
        }
    }

    registerOnChange(fn: (p: Sex) => void) {
        this.onChange = fn;
        this.onChange(this.sex);
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
        this.onTouched();
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

}
