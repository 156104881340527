<app-logged-navbar [withBackground]="true">
    <div class="request-loan">
        <span class="page-title">
            Nuevo crédito
        </span>
        <mat-horizontal-stepper class="form-stepper wrap" linear #stepper>
            <mat-step label="Datos personales" [completed]="stepCompleted[0]" [editable]="!stepCompleted[0]">
                <app-request-loan-scoring-step (next)="nextStep()" [(loan)]="loan"></app-request-loan-scoring-step>
            </mat-step>
            <mat-step label="Documentación" [completed]="stepCompleted[1]" [editable]="!stepCompleted[1]">
                <app-request-loan-documentation-step (next)="nextStep()" [(loan)]="loan" *ngIf="loan"></app-request-loan-documentation-step>
            </mat-step>
            <mat-step label="Solicitar crédito" [completed]="stepCompleted[2]" [editable]="!stepCompleted[2]">
                <app-request-loan-details-step (next)="nextStep()" [(loan)]="loan" *ngIf="loan"></app-request-loan-details-step>
            </mat-step>
            <mat-step label="Revisión" [completed]="stepCompleted[3]" *ngIf="loan && loan.state === 'revision'" [editable]="!stepCompleted[3]">
                <app-request-loan-revision-step (next)="nextStep()"></app-request-loan-revision-step>
            </mat-step>
            <mat-step label="Datos bancarios" [completed]="stepCompleted[loan?.state === 'revision' ? 4 : 3]" [editable]="!stepCompleted[loan?.state === 'revision' ? 4 : 3]">
                <app-request-loan-account-step (next)="nextStep()" [(loan)]="loan" *ngIf="loan"></app-request-loan-account-step>
            </mat-step>
            <mat-step label="Firma" [completed]="stepCompleted[loan?.state === 'revision' ? 5 : 4]" [editable]="!stepCompleted[loan?.state === 'revision' ? 5 : 4]">
                <app-request-loan-signature-step (next)="nextStep()" [(loan)]="loan" *ngIf="loan"></app-request-loan-signature-step>
            </mat-step>
            <mat-step label="Subscripción de pago" [completed]="stepCompleted[loan?.state === 'revision' ? 6 : 5]" [editable]="!stepCompleted[loan?.state === 'revision' ? 6 : 5]">
                <app-request-loan-payment-subscription-step (next)="nextStep()" [(loan)]="loan" *ngIf="loan"></app-request-loan-payment-subscription-step>
            </mat-step>
            <mat-step label="Finalizar" [completed]="stepCompleted[loan?.state === 'revision' ? 7 : 6]">
                <app-request-loan-done-step *ngIf="loan"></app-request-loan-done-step>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</app-logged-navbar>
