<app-unlogged-navbar [leftButtons]="navbarLeftButtons" [rightButtons]="navbarRightButtons" [withBackground]="true">
    <div class="login">
        <h1 class="title">
            Iniciar sesión
        </h1>
        <form class="login-card-container" [formGroup]="loginFG" (submit)="login()">
            <div class="login-card">
                <h2 class="login-title">Ingresá tus datos</h2>
                <span class="login-description">Si todavía no tenés una cuenta podés crearla haciendo click <a routerLink="/session/create-account" class="link">aquí</a>.</span>
                <span class="error-message">{{ error }}</span>

                <div class="inputs-container">
                    <mat-form-field appearance="outline" class="light-input win2win-form-field">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email">
                        <mat-error *ngIf="loginFG.controls.email.hasError('required')">
                            Por favor complete su email
                        </mat-error>
                        <mat-error *ngIf="loginFG.controls.email.hasError('email')">
                            Por favor ingrese un email válido
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="light-input win2win-form-field">
                        <mat-label>Contraseña</mat-label>
                        <input matInput formControlName="password" type="password">
                        <mat-error *ngIf="loginFG.controls.password.hasError('required')">
                            Por favor complete su contraseña
                        </mat-error>
                    </mat-form-field>
                </div>
                <a routerLink="/session/recovery/send" class="link">Recuperar contraseña</a>

                <div class="buttons">
                    <button mat-raised-button class="next win2win-button mini">
                        Entrar
                    </button>
                </div>
            </div>
        </form>

    </div>
</app-unlogged-navbar>
