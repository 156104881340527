import { LoanService } from './../../../../services/loan.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Loan } from '../../../../models/loan.model';
import { UtilsService } from '../../../../services/utils.service';
import { SignaturePad } from 'angular2-signaturepad';
import { ErrorResponse } from '../../../../models/error.model';

@Component({
    selector: 'app-request-loan-signature-step',
    templateUrl: './request-loan-signature-step.component.html',
    styleUrls: ['./request-loan-signature-step.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequestLoanSignatureStepComponent implements OnInit {

    loanValue?: Loan;
    @Input()
    set loan(loan: Loan) {
        // TODO Completar form
        if (!loan || loan.state !== 'signature') {
            this.signatureFG.disable();
            this.signaturePad?.off();
        } else {
            this.signatureFG.enable();
            this.signaturePad?.on();
        }
        this.loanValue = loan;
    }
    get loan() {
        return this.loanValue;
    }

    @Output()
    loanChange = new EventEmitter<Loan>();

    @Output()
    next = new EventEmitter<void>();

    signatureFG = new FormGroup({

    });

    signaturePadOptions = {
        backgroundColor: 'lightgrey',
    };

    @ViewChild(SignaturePad)
    signaturePad: SignaturePad;

    constructor(
        private loanService: LoanService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        const canvas = document.getElementsByTagName('canvas')[0];
        if (canvas) {
            this.resizeCanvas(canvas);
        }
    }

    continue() {
        if (!this.signaturePad.isEmpty()) {
            const file = this.utilsService.dataURLtoFile(this.signaturePad.toDataURL('image/svg+xml'), 'Firma.svg');
            this.loanService.uploadDocument(this.loan.id, file, 'signature').then(document => {
                this.loanService.sendSignature(this.loan.id, String(document.id)).subscribe(loan => {
                    this.loanChange.emit(loan);
                    this.next.emit();
                }, (error: ErrorResponse) => this.utilsService.alert('Error al confirmar crédito', error.error?.message));
            }, (error: ErrorResponse) => this.utilsService.alert('Error al subir archivo', error.error?.message));
        }
    }

    resizeCanvas(canvas: HTMLCanvasElement) {
        if (!canvas) return;
        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);
        this.signaturePad?.clear(); // otherwise isEmpty() might return incorrect value
    }
}
