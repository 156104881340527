import { ErrorResponse } from './../../../models/error.model';
import { UtilsService } from './../../../services/utils.service';
import { AnyDict } from './../../../models/generics.model';
import { environment } from './../../../../environments/environment';
import { AuthService } from './../../../services/auth.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarButton } from './../../navbar/unlogged-navbar/unlogged-navbar.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

    accountDataFG = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, (control: AbstractControl): AnyDict | null => {
            if (!control) return null;
            if (!/[A-Z]/.test(control.value)) {
                return { noUpperCase: true };
            }
            if (!/[a-z]/.test(control.value)) {
                return { noLowerCase: true };
            }
            if (!/\d/.test(control.value)) {
                return { noNumber: true };
            }
            return null;
        }]),
        passwordConfirm: new FormControl('', [Validators.required, (control: AbstractControl): AnyDict | null => {
            if (control && this.accountDataFG && control.value === this.accountDataFG.value.password) {
                return null;
            }
            return { notEquals: true };
        }])
    });

    phoneFG = new FormGroup({
        phone: new FormControl('', [Validators.required]),
        smsRequestIsATest: new FormControl('', [])
    });

    validationCodeFG = new FormGroup({
        validationCode: new FormControl('', [Validators.required]),
    });

    navbarLeftButtons: NavbarButton[] = [
    ];

    navbarRightButtons: NavbarButton[] = [
        { label: 'Preguntas frecuentes', onClick: () => this.router.navigate(['faq']) },
        { label: 'Iniciar sesión', onClick: () => this.router.navigate(['session', 'login']), raised: true }
    ];

    currentTab = 0;
    transactionCode = '';
    captchaKey = environment.captchaKey;
    resendTimer: number;
    sendingType: 'sms' | 'email' | 'email-check';
    mercadolibreId?: string;

    @ViewChild('captchaRef', { static: false })
    captchaRef: RecaptchaComponent;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params.email) {
                this.accountDataFG.controls.email.setValue(params.email);
            }
            if (params.ml_id) {
                this.mercadolibreId = params.ml_id;
            }
        });
        this.accountDataFG.controls.password.valueChanges.subscribe(() => {
            setTimeout(() => this.accountDataFG.controls.passwordConfirm.updateValueAndValidity(), 10);
        });
    }

    continueAccountData() {
        if (this.accountDataFG.valid) {
            this.sendingType = 'email-check';
            this.captchaRef.reset();
            this.captchaRef.execute();
        }
    }

    sendSms() {
        if (this.phoneFG.valid) {
            this.sendingType = 'sms';
            this.captchaRef.reset();
            this.captchaRef.execute();
        }
    }

    sendEmail() {
        if (this.phoneFG.valid) {
            this.sendingType = 'email';
            this.captchaRef.reset();
            this.captchaRef.execute();
        }
    }

    resolvedCaptcha(captchaToken: string) {
        if (captchaToken && this.sendingType === 'email-check') {
            const email = this.accountDataFG.value.email;

            this.authService.checkEmail(email, captchaToken).subscribe(response => {
                this.currentTab++;

            }, (error: ErrorResponse) => this.utilsService.alert('Credenciales invalidas', error.error?.message));

        } else if (captchaToken) {
            const phone = this.sendingType === 'sms' ? this.phoneFG.value.phone : undefined;
            const email = this.sendingType === 'email' ? this.accountDataFG.value.email : undefined;

            this.authService.sendActivationSms(phone, email, captchaToken, this.phoneFG.value.smsRequestIsATest).subscribe(response => {
                this.transactionCode = response.transactionCode;
                this.currentTab = 2;
                this.resendTimer = 30;
                const interval = setInterval(() => {
                    this.resendTimer -= 1;
                    if (this.resendTimer <= 1) {
                        this.resendTimer = 0;
                        clearInterval(interval);
                    }
                }, 1000);
                if (this.phoneFG.value.smsRequestIsATest && response.secret) {
                    this.validationCodeFG.controls.validationCode.setValue(response.secret);
                }
            }, (error: ErrorResponse) => this.utilsService.alert('Error al enviar código', error.error?.message));
        }
    }

    createAccount() {
        if (this.accountDataFG.valid && this.phoneFG.valid && this.validationCodeFG.valid) {
            this.authService.createAccount(
                this.accountDataFG.value.email,
                this.accountDataFG.value.password,
                this.phoneFG.value.phone,
                this.validationCodeFG.value.validationCode,
                this.transactionCode,
                this.sendingType,
                this.mercadolibreId
            ).subscribe(session => {
                this.authService.saveToken(session.token);
                this.currentTab++;
            }, (error: ErrorResponse) => this.utilsService.alert('Error al crear usuario', error.error?.message));
        }
    }
}
