<div class="page-container" [ngClass]="{ 'with-background': withBackground }">
    <div class="logged-navbar">
        <div class="left">
           <img class="logo" src="/assets/image/logo/logo.png" alt="">
           <ng-container *ngIf="!utilsService.isMobile()">
                <button mat-button class="left-button" routerLink="/dashboard">
                    Volver al inicio
                </button>
           </ng-container>
        </div>
        <div class="right">
            <ng-container *ngIf="!utilsService.isMobile()">
                <span class="hello-message">Hola!</span>
                <button mat-icon-button class="icon-button" [matMenuTriggerFor]="notificationsMenu" (click)="getNotifications()">
                    <mat-icon [matBadge]="unreadNotificationsCount" matBadgeColor="accent" [matBadgeOverlap]="true" [matBadgeHidden]="unreadNotificationsCount === 0">notifications</mat-icon>
                </button>
                <button mat-icon-button class="icon-button" routerLink="/profile">
                    <mat-icon>person_outline</mat-icon>
                </button>
                <button mat-raised-button class="right-button win2win-button" (click)="logout()">
                    Cerrar sesión
                </button>
            </ng-container>
            <button mat-icon-button *ngIf="utilsService.isMobile()" class="menu-button" (click)="menuOpen = !menuOpen">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </div>
    <div class="page-content">
        <ng-content></ng-content>
    </div>
    <app-footer></app-footer>
</div>
<div class="side-menu-overlay" [ngClass]="{ open: menuOpen }" (click)="menuOpen = false"></div>
<div class="side-menu" [ngClass]="{ open: menuOpen }" *ngIf="utilsService.isMobile()">
    <button mat-icon-button class="menu-icon-button" (click)="menuOpen = !menuOpen">
        <mat-icon>menu</mat-icon>
    </button>
    <ng-container *ngFor="let menuButton of getMenuButtons()">
         <span class="button link" (click)="menuButton.onClick()">
             {{ menuButton.label }}
         </span>
    </ng-container>
</div>

<mat-menu #notificationsMenu="matMenu" xPosition="before">
    <div class="notifications-menu" (click)="$event.stopPropagation()">
        <div class="header">
            <h2 class="title">
                Notificaciones
            </h2>
            <a class="view-all" routerLink="/notifications">
                Ver todas
            </a>
        </div>
        <div class="notification-list">
            <div class="notification" *ngFor="let notification of notifications" [ngClass]="{ read: notification.read }" [routerLink]="'/notifications/'+notification.id">
                <h3 class="title">
                    {{ notification.title }}
                </h3>
                <span class="description">
                    {{ notification.content }}
                </span>
            </div>
        </div>
    </div>
</mat-menu>
