import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ErrorResponse } from './../models/error.model';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { Router, UrlSerializer } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import * as moment from 'moment'; // npm install moment --save
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export interface PaginatedResponse<T> {
  data: T;
  totalElementCount: number;
}

export type StringDict<ValueType = any> = { [key: string]: ValueType };

interface DocumentURLResponse {
    url: string;
    key: string;
    fields: StringDict;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    private mobile = false;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private serializer: UrlSerializer,
        private breakpointObserver: BreakpointObserver,
        private http: HttpClient
    ) {
        breakpointObserver.observe(['(max-width: 800px)']).subscribe(result => this.mobile = result.matches);
    }

    beautifyNumber(n: any, digits: number = 0): string {
        if (!(n instanceof Number)) {
            n = Number(n);
        }
        return Intl.NumberFormat('es-AR', { minimumFractionDigits: digits, maximumFractionDigits: digits }).format(n);
    }

    beautifyText(text: any): string {
        if (text) {
            const firstMayus = text.charAt(0).toUpperCase() + text.slice(1);
            return firstMayus.replaceAll('_', ' ');
        }
    }

    beautifyDate(date: string, format = 'LLL'): string {
        const d = moment(date);
        d.locale('es');
        return d.format(format);
    }

    downLoadFile(response: any, filename: string) {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
    }

    alert(title: string, description: string, panelClass?: string) {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: title,
                descripcion: description,
                botonAceptar: 'Aceptar'
            },
            panelClass
        });
    }

    confirm(title: string, description: string) {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                titulo: title,
                descripcion: description,
                botonCancelar: 'Cancelar',
                botonAceptar: 'Confirmar'
            }
        });
    }

    serializeUrl(urlArray: any[], queryParams: any) {
        const url = this.router.createUrlTree(urlArray, { queryParams });
        return this.serializer.serialize(url);
    }

    isMobile(): boolean {
        return this.mobile;
    }

    obtenerDecimales(numero: number, cantidadDeDecimales: number): string {
        return Math.floor((numero % 1) * Math.pow(10, cantidadDeDecimales)).toLocaleString('es-AR', {minimumIntegerDigits: cantidadDeDecimales});
    }

    paginatedRequest<T>(url, pageNumber: number, itemsPerPage: number): Observable<PaginatedResponse<T>> {
        return this.http.get<T>(url, { observe: 'response', headers: {
            'cantidad-registros': itemsPerPage.toString(),
            'numero-pagina': pageNumber.toString()
        }}).pipe(map(response => {
            return {
                data: response.body,
                totalElementCount: Number.parseInt(response.headers.get('total-registros'), 10)
            };
        }));
    }

    uploadFile<ReturnType>(file: File, getUrlEndpoint: string, reportUploadEndpoint: string, getUrlExtraParams: any, reportUploadExtraParams: any) {
        return new Promise<ReturnType>((resolve, reject) => {
            this.http.post<DocumentURLResponse>(getUrlEndpoint, { fileName: file.name }).subscribe(urlData => {
                const form = new FormData();
                Object.keys(urlData.fields).forEach(key => form.append(key, urlData.fields[key]));
                form.append('file', file, file.name);
                this.http.post(urlData.url, form).subscribe(_ => {
                    this.http.post<ReturnType>(reportUploadEndpoint, Object.assign({ fileName: file.name, key: urlData.key }, reportUploadExtraParams)).subscribe(
                        response => resolve(response),
                        (error: ErrorResponse) => reject(error)
                    );
                });
            }, (error: ErrorResponse) => reject(error));
        });
    }

    numberFormValidator(control: AbstractControl): ValidationErrors {
        if (isNaN(control.value)) {
            return { number: true };
        }
        return {};
    }

    dataURLtoFile(dataURI: string, fileName: string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new File([new Blob([ab], {type: mimeString})], fileName);
        return blob;
    }
}
