import { environment } from './../../../../../environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';
import { UtilsService } from './../../../../services/utils.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarButton } from '../../../navbar/unlogged-navbar/unlogged-navbar.component';

@Component({
    selector: 'app-send-recovery-email',
    templateUrl: './send-recovery-email.component.html',
    styleUrls: ['./send-recovery-email.component.scss']
})
export class SendRecoveryEmailComponent implements OnInit {

    navbarLeftButtons: NavbarButton[] = [
        { label: 'Preguntas frecuentes', onClick: () => this.router.navigate(['faq']) },
    ];

    navbarRightButtons: NavbarButton[] = [
        { label: 'Iniciar sesión', onClick: () => this.router.navigate(['session', 'login']) },
        { label: 'Crear cuenta', onClick: () => this.router.navigate(['session', 'create-account']), raised: true }
    ];

    sendRecoveryFG = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    error: string;
    captchaKey = environment.captchaKey;

    @ViewChild('captchaRef', { static: false })
    captchaRef: RecaptchaComponent;

    constructor(
        private router: Router,
        private authService: AuthService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
    }

    sendRecoveryEmail() {
        if (this.sendRecoveryFG.valid) {
            this.captchaRef.reset();
            this.captchaRef.execute();
        }
    }

    resolvedCaptcha(captchaToken: string) {
        if (captchaToken) {
            this.error = undefined;
            this.authService.sendRecoveryEmail(this.sendRecoveryFG.value.email, captchaToken).subscribe(session => {
                this.utilsService.alert('Email enviado correctamente', 'Por favor revise su bandeja de entrada para recuperar su contraseña.')
                    .afterClosed().subscribe(_ => {
                        this.router.navigate(['session', 'login']);
                    });
            }, error => this.error = error.error.message);
        }
    }

}
