import { DataTableComponent } from './components/generics/data-table/data-table.component';
import { SexSelectorComponent } from './components/generics/sex-selector/sex-selector.component';
import { PhoneInputComponent } from './components/generics/phone-input/phone-input.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MaterialModule } from './material-module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './components/home/home.component';
import { UnloggedNavbarComponent } from './components/navbar/unlogged-navbar/unlogged-navbar.component';
import { FaqComponent } from './components/information/faq/faq.component';
import { TermsAndConditionsComponent } from './components/information/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/information/privacy-policy/privacy-policy.component';
import { InformationLayoutComponent } from './components/information/information-layout/information-layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { CreateAccountComponent } from './components/session/create-account/create-account.component';
import { LoginComponent } from './components/session/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { DashboardComponent } from './components/logged/dashboard/dashboard.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { LoggedNavbarComponent } from './components/navbar/logged-navbar/logged-navbar.component';
import { RequestLoanComponent } from './components/logged/request-loan/request-loan.component';
import { RequestLoanScoringStepComponent } from './components/logged/request-loan/request-loan-scoring-step/request-loan-scoring-step.component';
import { RequestLoanDocumentationStepComponent } from './components/logged/request-loan/request-loan-documentation-step/request-loan-documentation-step.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { RequestLoanDetailsStepComponent } from './components/logged/request-loan/request-loan-details-step/request-loan-details-step.component';
import { RequestLoanAccountStepComponent } from './components/logged/request-loan/request-loan-account-step/request-loan-account-step.component';
import { RequestLoanRevisionStepComponent } from './components/logged/request-loan/request-loan-revision-step/request-loan-revision-step.component';
import { RequestLoanDoneStepComponent } from './components/logged/request-loan/request-loan-done-step/request-loan-done-step.component';
import { FeesListDialogComponent } from './dialogs/fees-list-dialog/fees-list-dialog.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NotificationsListComponent } from './components/logged/notifications-list/notifications-list.component';
import { NotificationDetailsComponent } from './components/logged/notifications-list/notification-details/notification-details.component';
import { ProfileComponent } from './components/logged/profile/profile.component';
import { RecoverPasswordComponent } from './components/session/recover-password/recover-password.component';
import { SendRecoveryEmailComponent } from './components/session/recover-password/send-recovery-email/send-recovery-email.component';
import { RequestLoanSignatureStepComponent } from './components/logged/request-loan/request-loan-signature-step/request-loan-signature-step.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CameraDialogComponent } from './dialogs/camera-dialog/camera-dialog.component';
import { WebcamModule } from 'ngx-webcam';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { RequestLoanPaymentSubscriptionStepComponent } from './components/logged/request-loan/request-loan-payment-subscription-step/request-loan-payment-subscription-step.component'

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        ConfirmDialogComponent,
        HomeComponent,
        UnloggedNavbarComponent,
        FaqComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        InformationLayoutComponent,
        FooterComponent,
        CreateAccountComponent,
        LoginComponent,
        PhoneInputComponent,
        DashboardComponent,
        LoggedNavbarComponent,
        RequestLoanComponent,
        SexSelectorComponent,
        RequestLoanScoringStepComponent,
        RequestLoanDocumentationStepComponent,
        RequestLoanDetailsStepComponent,
        RequestLoanAccountStepComponent,
        RequestLoanRevisionStepComponent,
        RequestLoanDoneStepComponent,
        FeesListDialogComponent,
        DataTableComponent,
        NotificationsListComponent,
        NotificationDetailsComponent,
        ProfileComponent,
        RecoverPasswordComponent,
        SendRecoveryEmailComponent,
        RequestLoanSignatureStepComponent,
        CameraDialogComponent,
        RequestLoanPaymentSubscriptionStepComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatCarouselModule.forRoot(),
        NgHttpLoaderModule.forRoot(),
        NgxDatatableModule,
        SignaturePadModule,
        WebcamModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    entryComponents: [
        ConfirmDialogComponent,
        FeesListDialogComponent,
        CameraDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
