import { UtilsService } from './../../../services/utils.service';
import { Component, Input, OnInit } from '@angular/core';

export interface NavbarButton {
    label: string;
    onClick: () => void;
    raised?: boolean;
}

@Component({
  selector: 'app-unlogged-navbar',
  templateUrl: './unlogged-navbar.component.html',
  styleUrls: ['./unlogged-navbar.component.scss']
})
export class UnloggedNavbarComponent implements OnInit {

    menuOpen = false;

    @Input()
    leftButtons: NavbarButton[];

    @Input()
    rightButtons: NavbarButton[];

    @Input()
    withBackground?: boolean;

    constructor(
        public utilsService: UtilsService
    ) { }

    ngOnInit(): void {
    }

    getMenuButtons() {
        return [...this.leftButtons, ...this.rightButtons];
    }
}
