<app-unlogged-navbar [leftButtons]="navbarLeftButtons" [rightButtons]="navbarRightButtons" [withBackground]="true">
    <div class="create-account">
        <h1 class="title">
            Creá tu cuenta
        </h1>
        <form class="create-account-card email-card" *ngIf="currentTab === 0" [formGroup]="accountDataFG" (submit)="continueAccountData()">
            <h2 class="create-account-title">Ingresá tus datos</h2>
            <div class="create-account-description">Ingresa tu mail y contraseña que utilizarás para acceder a tu cuenta.</div>

            <div class="inputs-container">
                <mat-form-field appearance="outline" class="light-input win2win-form-field">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-error *ngIf="accountDataFG.controls.email.hasError('required')">
                        Por favor complete su email
                    </mat-error>
                    <mat-error *ngIf="accountDataFG.controls.email.hasError('email')">
                        Por favor ingrese un email válido
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="light-input win2win-form-field">
                    <mat-label>Contraseña</mat-label>
                    <input matInput formControlName="password" type="password">
                    <mat-hint>Debe tener al menos una mayúscula, una minúscula y un número</mat-hint>
                    <mat-error *ngIf="accountDataFG.controls.password.hasError('required')">
                        Por favor complete su contraseña
                    </mat-error>
                    <mat-error *ngIf="accountDataFG.controls.password.hasError('noLowerCase')">
                        La contraseña debe tener una letra minúscula
                    </mat-error>
                    <mat-error *ngIf="accountDataFG.controls.password.hasError('noUpperCase')">
                        La contraseña debe tener una letra mayúscula
                    </mat-error>
                    <mat-error *ngIf="accountDataFG.controls.password.hasError('noNumber')">
                        La contraseña debe tener un número
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="light-input win2win-form-field">
                    <mat-label>Reingresar contraseña</mat-label>
                    <input matInput formControlName="passwordConfirm" type="password">
                    <mat-error *ngIf="accountDataFG.controls.passwordConfirm.hasError('required')">
                        Por favor vuelva a ingresar su contraseña
                    </mat-error>
                    <mat-error *ngIf="accountDataFG.controls.passwordConfirm.hasError('notEquals')">
                        Las contraseñas no son iguales
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="buttons">
                <button mat-raised-button class="next win2win-button mini">
                    Continuar
                </button>
            </div>
        </form>
        <form class="create-account-card phone-card" *ngIf="currentTab === 1" [formGroup]="phoneFG" (submit)="sendSms()">
            <h2 class="create-account-title">Ingresá tu teléfono</h2>
            <div class="create-account-description">Para activar tu cuenta recibirás un sms, verificá de ingresar tu teléfono correcatmente.</div>
            <div class="inputs-container">
                <mat-form-field appearance="outline" class="light-input phone-input win2win-form-field">
                    <mat-label>Teléfono</mat-label>
                    <app-phone-input formControlName="phone"></app-phone-input>
                    <mat-hint>Ejemplo: +54-11-99999999</mat-hint>
                </mat-form-field>
                <mat-checkbox formControlName="smsRequestIsATest">Sms Test</mat-checkbox>
            </div>
            <div class="buttons">
                <button mat-button type="button" class="back" (click)="currentTab = currentTab - 1">
                    Cambiar datos
                </button>
                <button mat-raised-button class="next win2win-button mini">
                    Enviar
                </button>
            </div>
        </form>
        <form class="create-account-card validation-phone-card" *ngIf="currentTab === 2" [formGroup]="validationCodeFG" (submit)="createAccount()">
            <h2 class="create-account-title">Validá tu identidad</h2>
            <div class="create-account-description">Para activar tu cuenta ingresá el código que recibiste por sms al número +{{ this.phoneFG.value.phone?.country }}-{{ this.phoneFG.value.phone?.area }}-{{ this.phoneFG.value.phone?.phoneNumber }}.</div>
            <div class="inputs-container">
                <mat-form-field appearance="outline" class="light-input validation-code-input win2win-form-field">
                    <mat-label>Código</mat-label>
                    <input matInput formControlName="validationCode">
                </mat-form-field>
                <span class="resend-timer" *ngIf="resendTimer !== 0">
                    {{ resendTimer }} segúndos para reenviar.
                </span>
                <div class="resend-options" *ngIf="resendTimer === 0">
                    <a class="link resend-link" (click)="sendSms()">
                        Volver a enviar
                    </a>
                    <a class="link resend-link" (click)="sendEmail()">
                        Enviar por email
                    </a>
                </div>
            </div>
            <div class="buttons">
                <button mat-button type="button" class="back" (click)="currentTab = currentTab - 1">
                    Cambiar número
                </button>
                <button mat-raised-button class="next win2win-button mini">
                    Crear cuenta
                </button>
            </div>
        </form>
        <div class="create-account-card msg-validation-ok-card" *ngIf="currentTab === 3">
            <h2 class="create-account-title">¡Felicitaciones!</h2>
            <div class="create-account-description">Tu cuenta ha sido creada.<br>Ya falta poco para que tengas tu crédito.</div>
            <div class="inputs-container">
                <img src="/assets/image/scoring-result/approved.png" alt="" class="validation-phone-approved-icon">
            </div>
            <div class="buttons">
                <button mat-raised-button class="next win2win-button mini" routerLink="/loans">
                    Continuar
                </button>
            </div>
        </div>
        <re-captcha #captchaRef="reCaptcha" (resolved)="resolvedCaptcha($event)" [siteKey]="captchaKey" size="invisible"></re-captcha>
    </div>
</app-unlogged-navbar>
