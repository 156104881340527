import { ActivatedRoute } from '@angular/router';
import { Notification } from './../../../../models/notification.model';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../services/utils.service';
import { NotificationService } from '../../../../services/notification.service';
import { ErrorResponse } from '../../../../models/error.model';

@Component({
    selector: 'app-notification-details',
    templateUrl: './notification-details.component.html',
    styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {

    notification: Notification;

    constructor(
        private notificationService: NotificationService,
        public utilsService: UtilsService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.notificationService.getNotificationById(params.id).subscribe(notification => {
                this.notification = notification;
            }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener notificación', error.error?.message));
        });
    }

}
