<app-information-layout title="Preguntas frecuentes">
    <div class="faq">
        <div class="question" *ngFor="let faq of questions" [ngClass]="{ open: faq.show }">
            <div class="question-title-container" (click)="faq.show = !faq.show">
                <p class="question-title">{{ faq.question }}</p>
                <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
            </div>
            <p class="answer" [innerHTML]="faq.answer"></p>
        </div>
    </div>
</app-information-layout>
