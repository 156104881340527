import { ID } from './../models/id.model';
import { Notification } from './../models/notification.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Loan } from '../models/loan.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    constructor(
        private http: HttpClient,
    ) { }

    getNotifications(page: number, itemsPerPage: number, extraParams: any) {
        const params = {
            'page-number': String(page),
            'page-size': String(itemsPerPage)
        };

        Object.assign(params, JSON.parse(JSON.stringify(extraParams)));

        return this.http.get<Notification[]>(`/notifications`, { params, observe: 'response' });
    }

    getNotificationsCount() {
        return this.http.get<number>(`/notifications/unread`);
    }

    getNotificationById(id: ID) {
        return this.http.get<Notification>(`/notifications/${id}`);
    }
}
