<app-unlogged-navbar [leftButtons]="navbarLeftButtons" [rightButtons]="navbarRightButtons" [withBackground]="true">
    <div class="recover-password">
        <h1 class="title">
            Recuperación de cuenta
        </h1>
        <form class="card-container" [formGroup]="recoveryFG" (submit)="recoverPassword()">
            <div class="card">
                <h2 class="card-title">Ingresá tu nueva contraseña</h2>
                <span class="card-description">Por favor ingresa tus nuevas credenciales para poder restablecer tu cuenta.</span>
                <span class="error-message">{{ error }}</span>

                <div class="inputs-container">
                    <mat-form-field appearance="outline" class="light-input win2win-form-field">
                        <mat-label>Nueva contraseña</mat-label>
                        <input matInput formControlName="password" type="password">
                        <mat-hint>Debe tener al menos una mayúscula, una minúscula y un número</mat-hint>
                        <mat-error *ngIf="recoveryFG.controls.password.hasError('required')">
                            Por favor complete su contraseña
                        </mat-error>
                        <mat-error *ngIf="recoveryFG.controls.password.hasError('noLowerCase')">
                            La contraseña debe tener una letra minúscula
                        </mat-error>
                        <mat-error *ngIf="recoveryFG.controls.password.hasError('noUpperCase')">
                            La contraseña debe tener una letra mayúscula
                        </mat-error>
                        <mat-error *ngIf="recoveryFG.controls.password.hasError('noNumber')">
                            La contraseña debe tener un número
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="light-input win2win-form-field">
                        <mat-label>Reingresar contraseña</mat-label>
                        <input matInput formControlName="passwordConfirm" type="password">
                        <mat-error *ngIf="recoveryFG.controls.passwordConfirm.hasError('required')">
                            Por favor vuelva a ingresar su contraseña
                        </mat-error>
                        <mat-error *ngIf="recoveryFG.controls.passwordConfirm.hasError('notEquals')">
                            Las contraseñas no son iguales
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="buttons">
                    <button mat-raised-button class="next win2win-button mini">
                        Enviar
                    </button>
                </div>
            </div>
        </form>

    </div>
</app-unlogged-navbar>
