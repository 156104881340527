import { Injectable } from '@angular/core';

export interface FAQ {
    question: string;
    answer: string;
    show?: boolean;
}


@Injectable({
    providedIn: 'root'
})
export class InformationService {

    faqs: FAQ[] = [
        {
            question: '¿Qué es Win2Win microcréditos?',
            answer: 'Es una empresa Fintech que ofrece de manera simple y rápida acceso a créditos a través de una plataforma 100% online. Otorgamos préstamos en el día de manera sencilla, sin mayores requisitos que el de una cuenta bancaria radicada en Argentina. No se requieren avales ni garantías y desde la comodidad de su celular o pc, podrán gestionar y recibir los préstamos con total seguridad y confianza.'
        },
        {
            question: '¿Cómo y cuando se reciben los préstamos?',
            answer: 'Una vez que el otorgamiento del préstamo fue confirmado, la acreditación (ver el dinero en la cuenta) demora entre 24 y 48 horas hábiles. El tiempo de acreditación depende del banco del cliente, NO de Win2Win. El dinero es depositado en la cuenta bancaria que ingresó en el formulario o confirmó con el asesor de Ventas de Win2Win.'
        },
        {
            question: '¿Cómo y cuando se abonan las cuotas?',
            answer: 'Las cuotas se debitan de mensualmente de la cuenta bancaria del cliente en la fecha pactada con el cliente.'
        },
        {
            question: ' ¿Tiene algún costo solicitar un crédito?',
            answer: 'Solicitar un crédito no tiene costo. Es 100% gratuito y seguro.'
        },
        {
            question: '¿Cuáles son los requisitos para pedir un crédito?',
            answer: 'Solo es necesario tener una cuenta bancaria a su nombre, estar radicado en Argentina y ser mayor a 18 años de edad.'
        },
        {
            question: '¿Qué datos tengo que aportar para la solicitud?',
            answer: 'Datos personales como CUIT-CUIL, número de teléfono, CBU.'
        },
        {
            question: '¿Mis datos son utilizados por Win2Win microcréditos?',
            answer: 'Tus datos son utilizados por Win2Win con el único objetivo de realizar una evaluación de tu perfil. Win2Win no divulgará tus datos con ninguna otra entidad, ni los utilizará con algún otro fin.'
        },
        {
            question: '¿Cuál es el importe de la cuota que se me debitará?',
            answer: 'En el contrato tenés el detalle de tu plan: monto mensual y plazo. Igualmente 48 hs hábiles previas a la fecha de vencimiento te enviaremos un correo electrónico y un SMS para recordártelo.'
        },
        {
            question: '¿Qué sucede si el vencimiento de una cuota cae en un día no hábil bancario?',
            answer: 'Si esto sucediera, la cuota vencerá el primer día hábil siguiente.'
        },
        {
            question: '¿Qué otra alternativa existe para el pago de las cuotas?',
            answer: 'Existen canales de pago alternativos. Para conocerlos deberás comunicarte con el equipo de cobranzas. Ellos te darán toda la información necesaria.'
        },
        {
            question: '¿Puedo pre-cancelar mi préstamo?',
            answer: 'Win2Win microcréditos te da la posibilidad de precancelar tu préstamo en cualquier momento.'
        },
    ];

    constructor() { }

    getFaqs(): FAQ[] {
        return this.faqs;
    }

    getHomeFaqs() {
        return [this.faqs[2], this.faqs[3], this.faqs[5], this.faqs[10]];
    }
}
