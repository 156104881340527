<app-logged-navbar>
    <div class="dashboard">
        <span class="page-title">
            Mis créditos
        </span>
        <div class="no-loans" *ngIf="!loans.length">
            No hay créditos creados.
            <button mat-raised-button class="win2win-button" routerLink="/loans">
                Comenzá tu crédito
            </button>
        </div>
        <div class="loan-list" *ngIf="loans.length">
            <div class="loan-card new-loan">
                <span class="card-title">
                    Nuevo crédito
                </span>
                <button mat-raised-button class="continue-loan-button win2win-button" routerLink="/loans">
                    Comenzar
                </button>
            </div>

            <div class="loan-card" *ngFor="let loan of loans">
                <span class="card-title">
                    ${{ utilsService.beautifyNumber(loan.requestedAmount, 0) }}
                </span>
                <div class="card-description">
                    <span class="description-item">
                        TNA: {{ utilsService.beautifyNumber(loan.nominalRate * 100, 0) }}%
                    </span>
                    <span class="description-item">
                        Plazo: {{ loan.term }} meses
                    </span>
                </div>
                <div class="status">
                    <span class="status-label">Estado</span>
                    <span class="status-value">{{ translateStatus(loan.state) }}</span>
                </div>
                <button mat-raised-button class="continue-loan-button win2win-button" (click)="onCardButtonClick(loan)" [disabled]="loan.state === 'rejected'">
                    {{ getCardButtonText(loan) }}
                </button>
            </div>
        </div>
    </div>
</app-logged-navbar>
