<ngx-datatable
    class="data-table"
    [rows]="rows"
    [columns]="columns"
    [columnMode]="ColumnMode.standard"
    [headerHeight]="50"
    rowHeight="auto"
    [reorderable]="true"
    [externalSorting]="!!sortFn"
    sortType="multi"
    (sort)="sortFn.emit($event)"
    [messages]="{emptyMessage: rows === undefined ? 'Consultando...' : 'Sin datos'}"
>

    <ngx-datatable-column *ngFor="let column of columns" [name]="column.name" [prop]="column.prop" [minWidth]="column.minWidth" [maxWidth]="column.maxWidth" [sortable]="column.sortable" [cellClass]="column.cellClass">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <div [innerHtml]="column.parseValue ? column.parseValue(value, column) : value">
            </div>
            <ng-container [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{ rowIndex: rowIndex, value: value, row: row, column: column }" *ngIf="column.template"></ng-container>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="" [width]="menuButtons.length === 1 ? 110 : 70" [minWidth]="menuButtons.length === 1 ? 130 : 60" [maxWidth]="menuButtons.length === 1 ? 200 : 80" *ngIf="menuButtons && menuButtons.length" [sortable]="false">
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <button mat-raised-button class="menu-single-button" color="primary" (click)="menuButtons[0].action(row)" *ngIf="menuButtons.length === 1" [disabled]="menuButtons[0].isDisabled ? menuButtons[0].isDisabled(row) : false">
                {{ menuButtons[0].label }}
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="activeMenuRow = row" *ngIf="menuButtons.length > 1">
                <mat-icon> more_vert </mat-icon>
            </button>
        </ng-template>
    </ngx-datatable-column>

</ngx-datatable>
<mat-paginator
    class="paginator"
    [length]="paginatorStatus.totalElementCount"
    [pageSize]="paginatorStatus.itemsPerPage"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageIndex]="paginatorStatus.page"
    (page)="callUpdateRows($event)"
    *ngIf="paginatorStatus"
>
</mat-paginator>
<mat-menu #menu="matMenu" *ngIf="menuButtons.length > 1">
    <button mat-menu-item *ngFor="let button of menuButtons" (click)="button.action(activeMenuRow)" [disabled]="button.isDisabled ? button.isDisabled(activeMenuRow) : false">
        {{ button.label }}
    </button>
</mat-menu>

