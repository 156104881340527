<app-unlogged-navbar [leftButtons]="navbarLeftButtons" [rightButtons]="navbarRightButtons" [withBackground]="true">
    <div class="home">
        <div class="title-container">
            <div class="title-container-left">
                <h1 class="title">Microcreditos para financiar tu emprendimiento.</h1>
                <h2 class="description">Win2Win te ofrece la mejor tasa del mercado, en el acto.
                    En tres sencillos pasos obtenes el crédito que estás necesitando, 100% digital.
                </h2>
                <button mat-raised-button class="title-button win2win-button" routerLink="/session/create-account">
                    Pedí tu crédito
                </button>
            </div>
            <div class="title-container-right">
                <img class="title-image" src="/assets/image/home/title-image.jpg" alt="">
                <div class="gradient-decoration-2"></div>
            </div>
            <div class="gradient-decoration-1"></div>
        </div>
        <div #products></div>
        <mat-carousel
            timings="250ms ease-in"
            [autoplay]="false"
            interval="5000"
            color="accent"
            maxWidth="auto"
            slides="5"
            [proportion]="56.25"
            [loop]="true"
            [hideArrows]="false"
            [hideIndicators]="false"
            [useKeyboard]="false"
            [useMouseWheel]="false"
            [svgIconOverrides]="{ arrowBack: 'carousel-arrow-left', arrowForward: 'carousel-arrow-right' }"
            orientation="ltr"
            class="home-carousel"
        >
            <mat-carousel-slide
                #matCarouselSlide
                *ngFor="let slide of slides; let i = index"
                [image]="slide.image"
                [hideOverlay]="true"
            >
                <div class="carousel-slide">
                    <span class="slide-title">
                        {{ slide.title }}
                    </span>
                    <span class="slide-description">
                        {{ slide.description }}
                    </span>
                    <button mat-raised-button class="slide-button win2win-button" (click)="slide.onClick()">
                        {{ slide.buttonText }}
                    </button>
                </div>
            </mat-carousel-slide>
        </mat-carousel>
        <div class="steps">
            <span class="steps-title">
                Estás a 3 pasos de cumplir tus sueños
            </span>
            <div class="step step-{{i}}" *ngFor="let step of steps; let i = index">
                <img [src]="step.icon" alt="" class="step-icon">
                <div class="step-content">
                    <span class="step-title">
                        {{ step.title }}
                    </span>
                    <span class="step-description">
                        {{ step.description }}
                    </span>
                </div>
            </div>
        </div>
        <div class="requirements">
            <div class="requirements-left">
                <span class="requirements-title">
                    ¿Qué necesitas?
                </span>
                <span class="requirements-description">
                    Lorem ipsum dolor sit amet consectetur adipiscing, elit convallis cursus blandit pretium posuere, iaculis gravida ligula sodales venenatis.
                </span>
                <div class="requirement">
                    <div class="requirement-point"></div>
                    <span class="requirement-name">
                        Foto de DNI frente y dorso.
                    </span>
                </div>
                <div class="requirement">
                    <div class="requirement-point"></div>
                    <span class="requirement-name">
                        3 últimos comprobantes de ingreso.
                    </span>
                </div>
                <span class="requirements-details">
                    Recibo de sueldo, si estás en relación de dependencia, o facturas en caso de que seas autónomo o monotributista.
                </span>
            </div>
            <div class="requirements-right">
                <div class="gradient-decoration-3"></div>
                <img src="/assets/image/home/requirements.jpg" alt="" class="requirements-image">
            </div>
        </div>
        <div class="faqs-section">
            <span class="faqs-title">
                Preguntas frecuentes
            </span>
            <div class="faqs-slider">
                <button mat-icon-button class="arrow" (click)="previousFaq()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <div #faqsList class="faqs-list">
                    <div #faqsContainer class="faqs-container" [style.transform]="'translateX(' + faqsTranslation + 'px)'">
                        <div #faq class="faq" *ngFor="let faq of getFaqs(); let i = index" [ngClass]="{ selected: i === selectedFaq, hidden: isHiddenFaq(i) }" (click)="!isHiddenFaq(i) && setFaq(i)">
                            <img src="/assets/image/logo/logo-sin-texto.png" alt="" class="faq-logo" *ngIf="i !== selectedFaq">
                            <img src="/assets/image/logo/logo-sin-texto-blanco.svg" alt="" class="faq-logo" *ngIf="i === selectedFaq">
                            <span class="question">
                                {{ faq.question }}
                            </span>
                            <span class="answer">
                                {{ faq.answer }}
                            </span>
                        </div>
                    </div>
                </div>
                <button mat-icon-button class="arrow" (click)="nextFaq()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
            <a class="view-all-faqs" routerLink="/faq">
                Ver todas las preguntas frecuentes
            </a>
            <div class="gradient-decoration-4"></div>
        </div>
    </div>
</app-unlogged-navbar>
