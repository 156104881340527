<app-information-layout title="Política de privacidad">
    <div class="policy">
            <p>
                La Política de Privacidad describe el tratamiento que Win2Win brinda a la información que el Usuario aporte voluntariamente para acceder a los Servicios.
                El presente documento es parte integrante de los “Términos y Condiciones” aplicables al acceso y uso del sitio <a href="https://desa.win2win.com.ar">https://www.win2win.com.ar</a> (el “Sitio”). Mediante la aceptación de los los “Términos y Condiciones” en el momento de la registración el Ud. acepta las políticas aquí contenidas.
                Todo lo expresado en la Política de Privacidad se encuentra en cumplimiento de la Ley 25.326 de Protección de Datos Personales. Cualquier consulta o cuestiones relacionadas a la privacidad de los datos o la presente Política de Privacidad aquí enunciada, deben dirigirse a info@win2win.com.ar
                Todos los términos no definidos en la presente enunciados con mayúscula tendrán el significado que se les asigna en los “Términos y Condiciones”.
            </p>

            <span class="bold">1. FINALIDAD DE LA INFORMACIÓN SOLICITADA</span>
            <p>
                Los Servicios que brinda Win2Win requieren de información voluntaria provista por los Usuarios. Win2Win necesita requerir y validar los datos provistos por los Usuarios que permitan: (i) realizar una evaluación crediticia y documental inicial; (ii) realizar las validaciones pertinentes contra otras bases de datos; (iii) evaluar el otorgamiento del crédito solicitado e informar el límite de crédito finalmente otorgado y sus condiciones; (iv) verificar el historial y comportamiento crediticio de las personas humanas o jurídicas que soliciten los Servicios; (v) compartir la información y/o datos aplicables con los Usuarios Inversores y Usuarios Garantes que corresponda. A los efectos de las validaciones Win2Win (o quien realice las evaluaciones y validaciones por su cuenta y orden) realiza consultas a bases de datos públicas, privadas, empresas especializadas, organismos gubernamentales y centrales de créditos entre otras que permitan comprobar la veracidad de los datos. La aceptación de los “Términos y Condiciones” implican el consentimiento expreso del Usuario que autoriza a Win2Win (o en su caso, quien este designe) realice las evaluaciones y validaciones pertinentes.
            </p>

            <span class="bold">2. INFORMACIÓN QUE RECOLECTAMOS</span>
            <p>
                Win2Win, o quien éste indique, solicitará a los Usuarios que brinden información respecto a su identidad, cargos y/o facultades, datos filiatorios, de localización, del perfil crediticio, de facturación, fiscales y de sus condiciones económicas en general. Conjuntamente con estos datos, Win2Win o quien éste indique, recolectará información proveniente de consultas externas mencionadas en el párrafo anterior. La misma puede variar de tiempo en tiempo de acuerdo a las necesidades de Win2Win y las políticas crediticias.
            </p>

            <span class="bold">3. SOLICITUD Y RECOPILACIÓN DE LA INFORMACIÓN. CONFORMACIÓN DEL LEGAJO DIGITAL. TITULARIDAD DEL CONTENIDO</span>
            <p>
                Win2Win solicita principalmente la información a través de la plataforma "win2win.com.ar" que funciona en Internet y que dispone de formularios diseñados a tal efecto. Entre ellos solicita inicialmente el email, número de teléfono, número de documento y sexo para validar la identidad del Usuario contra bases de información de mercado. Una vez validada la identidad, los Usuarios Solicitantes completarán los datos y requerimientos de dichos formularios. También se podrá recopilar información de la actividad de los Usuarios en el uso de los Servicios en la Plataforma de Win2Win a partir de métodos de recolección informáticos y automatizados diseñados a fin de obtener información general y estadística sobre el uso del Sitio. Win2Win se reserva el derecho de instalar cookies de manera temporal para un mejor funcionamiento de la Plataforma sin que los mismos contengan información personal de los Usuarios.
            </p>
            <p>
                Los Usuarios Solicitantes, a su solo criterio, podrán aplicar a un préstamo, previa Solicitud de Préstamo para ser evaluado y eventualmente concedido por Win2Win. Como proceso inicial, los Usuarios Solicitantes deberán ingresar información que se le requiera y que conformará el legajo digital.
            </p>
            <p>
                Todo contenido ingresado a la Plataforma por los Usuarios Solicitantes (en adelante, el “Contenido del Usuario” o el “Contenido”) deberá ser de propiedad de éstos últimos, o deberán tener la autorización suficiente para publicarlo o compartirlo. Win2Win ni cualquier otro tercero que intervenga en el proceso serán responsables por una falsedad en las declaraciones que el Usuario Solicitante pudiera llegar a realizar al momento del ingreso del Contenido al Sitio, o por el Contenido ingresado.
            </p>
            <p>
                El Usuario Solicitante renuncia a todo derecho que le pudiera corresponder para inspeccionar o aprobar cualquier uso por parte de Win2Win del Contenido, como así también a cualquier compensación por tal uso. El Usuario renuncia también a formular cualquier reclamo contra Win2Win por cualquier supuesta violación de cualquier derecho relativo al Contenido o al material incluido en el mismo, reconociendo que bajo ningún concepto Win2Win se encontrará obligado a utilizar o publicar determinado Contenido.
            </p>
            <p>
                Queda entendido que Win2Win no puede controlar (ni se espera que controle) los Contenidos ingresados en el Sitio, incluyendo los comentarios que el Usuario pudiera incluir. Sin perjuicio de ello, y si bien Win2Win no realiza un control previo a los mensajes que cada Usuario pueda escribir, sí se reserva el derecho de, a su solo criterio, editar y/o eliminar mensajes, comentarios, solicitudes, Contenidos y/u opiniones de los Usuarios cuando los mismos violen las leyes de la República Argentina, la privacidad de otros Usuarios y/o personas, y/o contengan amenazas, agravios, ofensas, injurias, calumnias y/o atenten contra el decoro, buen nombre y honor de otro Usuario o persona, o que incluya lenguaje o material (texto o gráfico) adulto, obsceno o no apto para menores, atenten o induzcan a atentar contra los Términos y Condiciones o aquellos que intenten, por cualquier medio, interferir con el normal desarrollo del Sitio o de algún Servicio del mismo.
            </p>

            <span class="bold">4. ALMACENAMIENTO DE LA INFORMACIÓN</span>
            <p>
                Win2Win recopilará y almacenará la información en un sistema de cloud computing con las medidas de seguridad aplicables a fin de proteger la información personal de los Usuarios.
            </p>

            <span class="bold">5. UTILIZACIÓN DE LA INFORMACIÓN</span>
            <p>
                A los efectos de la utilización de la Plataforma, Win2Win necesitará realizar controles y chequeos que le permitan validar la identidad y facultades de los Usuarios y elaborar un informe de calificación del perfil crediticio de los Usuarios Solicitantes para informar al equipo de gestión crediticia de Win2Win. Estas verificaciones incluyen validaciones y controles de consistencia de la información provista así como verificaciones de autenticidad. Win2Win podrá compartir información con empresas que, incluyendo pero no limitando: (i) validen y verifiquen la información provista por el Usuario Solicitante; y/o (ii) realicen evaluaciones crediticias o releven información comercial o financiera relativa a los Servicios; y/o (iii) provean almacenamiento de la información; (iv) provean futuros servicios a través de la Plataforma a favor de los Usuarios Solicitantes. La aceptación de los “Términos y Condiciones” sirve a tales fines como suficiente, expreso e irrevocable consentimiento a favor de Win2Win a compartir tales datos ingresados. Sin perjuicio de ello, los terceros que reciban tales datos deberán cumplir con los regímenes aplicables en materia de Protección de Datos Personales.
                Una vez que los datos de identificación de los Usuarios Solicitantes sean verificados por cualquiera de los métodos de validación utilizados por Win2Win, o por quien este designe al efecto, éstos podrán ser actualizados en el Sitio. Si alguno de los datos mencionado hubiese cambiado desde que fue realizada la registración y eventual confirmación de estos, el Usuario, en su carácter de titular de los datos, se compromete a individualizar los mismos, y a solicitar a Win2Win la actualización respectiva, de cualquier dato que pudiere corresponder aceptando en consecuencia los Términos y Condiciones.
            </p>

            <span class="bold">6. TIEMPO DE GUARDA DE LA INFORMACIÓN</span>
            <p>
                La información aportada voluntariamente por los Usuarios será guardada por Win2Win durante todo el tiempo en que éstos estén vinculados a las operaciones de préstamos entre personas, y podrá ser eliminada por Win2Win una vez expirados los plazos legales de guarda y conservación de la documentación. Sin perjuicio de ello, Win2Win podrá conservar tal documentación e información incluso si tuviera que promover acciones judiciales o por cualquier otro motivo.
            </p>

            <span class="bold">7. ASPECTOS LEGALES RELATIVOS A LA INFORMACIÓN</span>
            <p>
                La ley que regula el manejo de las bases de datos es la Ley 25.326 de Protección de Datos Personales. El órgano de control de dicha Ley es la Agencia de Acceso a la Información Pública y tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. Según lo dispuesto por esta ley y las disposiciones complementarias emitidas por la Dirección Nacional de Protección de Datos Personales. Siendo los Usuarios Solicitantes los titulares de los datos personales en poder de Win2Win, aquellos tienen la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme los establecido en el artículo 14 inciso 3 de la Ley Nº 25.326.
            </p>

            <span class="bold">8. AUTORIZACIÓN PARA ACCEDER A LA INFORMACIÓN. DEBER DE INFORMAR</span>
            <p>
                Win2Win ha desarrollado un sistema consistente en el establecimiento de un proceso progresivo de registración. La información que brindan los Usuarios conforma la identidad de los Usuarios en los registros de Win2Win y por los cuales se administran los servicios. Win2Win, salvo autorización del Usuario, no dará a conocer la misma salvo que autoridad competente en cumplimiento de una ley o reglamentación vigente o en virtud de un proceso judicial notifique fehacientemente a Win2Win requerir del conocimiento de información del Usuario.
                En tales casos Win2Win tomará las medidas necesarias a fin de divulgar solamente y exclusivamente la información requerida legal o judicialmente y no otra y emplear sus mejores esfuerzos para que se proporcione un tratamiento confidencial a la misma. Win2Win destaca que se encuentra a disposición de cualquier autoridad gubernamental de la Argentina respecto a garantizar el cumplimiento de las leyes en materia de protección de datos personales, prevención del lavado de activos y financiación del terrorismo, o la realización de actividades ilícitas o prohibidas por ley entre otras.
                Los Usuarios toman conocimiento de que para la más efectiva prestación de los Servicios, Win2Win podrá contratar a terceros. En tal sentido, Win2Win queda expresa e irrevocablemente autorizada por los Usuarios a comunicar los datos del Usuario o la realización de sus actividades a terceros, en el país o en el exterior, con la finalidad de brindar los Servicios o cumplir la normativa aplicable y cooperar con las autoridades competentes en la medida en que discrecionalmente lo entienda conveniente, y necesario y adecuado en relación con cualquier investigación de un ilícito o un fraude, infracción de derechos de propiedad industrial o intelectual, u otra actividad que sea ilegal, en tanto se realice mediante una orden judicial, reglamentación, compromiso con una autoridad regulatoria de la Argentina y/o normativa aplicable.
                La información que provean los Usuarios Solicitantes es validada con bases de información públicas y privadas de diferentes características para realizar chequeos de identidad y crediticios. Aceptando los Términos y Condiciones dichos Usuarios autorizan a la Win2Win a realizar los mismos utilizando sus datos. En caso de adjudicación de los préstamos solicitados, Win2Win queda expresa e irrevocablemente autorizada a compartir los datos brindados por los Usuarios Solicitantes a los inversores de Win2Win, quienes podrán conocer sus identidades como así también la información crediticia.
            </p>

            <span class="bold">9. ASPECTOS GENERALES</span>
            <p>
                El Usuario reconoce que Win2Win tiene el derecho, pero no la obligación, de controlar en cualquier momento, y a su sola discreción el cumplimiento de los Términos y Condiciones, incluyendo las presentes Políticas de Privacidad, y cualquier otra norma o manual operativo que Win2Win pueda establecer oportunamente.
                Sin perjuicio de lo expresado anteriormente, el Usuario consiente y autoriza expresamente a Win2Win a transferir total o parcialmente la información recolectada a cualquiera de las sociedades controladas, controlantes y/o vinculadas a Win2Win, por cualquier título y en el momento, forma y condiciones que ésta establezca.
                Para comunicar cualquier duda o consulta, o violación detectada deberá enviar a info@win2win.com.ar con los datos necesarios para que Win2Win pueda tomar las medidas pertinentes.
            </p>
    </div>

</app-information-layout>
