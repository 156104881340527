import { UtilsService } from './../../../services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AnyDict } from '../../../models/generics.model';
import { User } from 'src/app/models/usuario.model';
import { ErrorResponse } from '../../../models/error.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    generalInformationFG = new FormGroup({
        name: new FormControl('', []),
        age: new FormControl('', []),
        cuit: new FormControl('', []),
        birth: new FormControl('', []),
        province: new FormControl('', []),
        city: new FormControl('', []),
        postalCode: new FormControl('', []),
        address: new FormControl('', [])
    });

    changePasswordFG = new FormGroup({
        password: new FormControl('', [Validators.required, (control: AbstractControl): AnyDict | null => {
            if (!control) return null;
            if (!/[A-Z]/.test(control.value)) {
                return { noUpperCase: true };
            }
            if (!/[a-z]/.test(control.value)) {
                return { noLowerCase: true };
            }
            if (!/\d/.test(control.value)) {
                return { noNumber: true };
            }
            return null;
        }]),
        passwordConfirm: new FormControl('', [Validators.required, (control: AbstractControl): AnyDict | null => {
            if (control && this.changePasswordFG && control.value === this.changePasswordFG.value.password) {
                return null;
            }
            return { notEquals: true };
        }])
    });

    constructor(
        private authService: AuthService,
        private utilsService: UtilsService,
        private router: Router
    ) { }

    ngOnInit(): void {
        let user = this.authService.getUser();

        this.authService.getSelfUser().subscribe(updatedUser => {
            if (user || updatedUser) {
                this.fillGeneralInformationForm(updatedUser ? updatedUser : user);
            }
        }, (error: ErrorResponse) => this.utilsService.alert('Error cargando los datos de perfil del usuario', error.error?.message));

    }

    fillGeneralInformationForm(user: User) {
        this.generalInformationFG.setValue({
            name: user?.personalInformation?.name ?? '',
            age: user?.personalInformation?.age ?? 0,
            cuit: user?.personalInformation?.cuit ?? '',
            birth: user?.personalInformation?.birth ?? '',
            province: user?.personalInformation?.province ?? '',
            city: user?.personalInformation?.city ?? '',
            postalCode: user?.personalInformation?.postalCode ?? '',
            address: user?.personalInformation?.address ?? '',
        });
    }

    saveGeneralInformation() {
        if (this.generalInformationFG.valid) {
            this.authService.updatePersonalInformation(this.generalInformationFG.value).subscribe(user => {
                this.utilsService.alert('Información actualizada correctamente', '');
                this.authService.saveUser(user);
            }, (error: ErrorResponse) => this.utilsService.alert('Error al actualizar información', error.error?.message));
        }
    }

    changePassword() {
        if (this.changePasswordFG.valid) {
            this.authService.changePassword(this.changePasswordFG.value.password).subscribe(_ => {
                const alert = this.utilsService.alert('Contraseña cambiada correctamente', 'Por favor vuelva a iniciar sesión para verificar su nueva contraseña.');
                alert.afterClosed().subscribe(() => {
                    this.authService.logout();
                    location.reload();
                });
            }, (error: ErrorResponse) => this.utilsService.alert('Error al cambiar contraseña', error.error?.message));
        }
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['home']);
    }
}
