import { Configuration } from './../models/configuration.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor(
        private http: HttpClient
    ) { }

    getConfigurations() {
        return this.http.get<Configuration[]>('/configurations');
    }
}
