<app-logged-navbar>
    <div class="notifications-list" *ngIf="notifications">
        <span class="page-title">
            Notificaciones
        </span>
        <div class="no-notifications" *ngIf="!notifications?.length">
            No hay notificaciones.
        </div>
        <div class="notification" *ngFor="let notification of notifications" [ngClass]="{ read: notification.read }" routerLink="/notifications/{{ notification.id }}">
            <span class="notification-title">{{ notification.title }}</span>
            <span class="notification-content">{{ notification.content }}</span>
            <span class="notification-date">{{ utilsService.beautifyDate(notification.added, 'L HH:mm') }}</span>
        </div>
        <mat-paginator
            class="paginator"
            [length]="paginatorStatus.totalElementCount"
            [pageSize]="paginatorStatus.itemsPerPage"
            [pageSizeOptions]="[5, 10, 25, 100]"
            [pageIndex]="paginatorStatus.page"
            (page)="updateNotifications($event)"
            *ngIf="paginatorStatus"
        ></mat-paginator>
    </div>
</app-logged-navbar>
