import { SendRecoveryEmailComponent } from './components/session/recover-password/send-recovery-email/send-recovery-email.component';
import { RecoverPasswordComponent } from './components/session/recover-password/recover-password.component';
import { ProfileComponent } from './components/logged/profile/profile.component';
import { NotificationDetailsComponent } from './components/logged/notifications-list/notification-details/notification-details.component';
import { NotificationsListComponent } from './components/logged/notifications-list/notifications-list.component';
import { RequestLoanComponent } from './components/logged/request-loan/request-loan.component';
import { DashboardComponent } from './components/logged/dashboard/dashboard.component';
import { LoginComponent } from './components/session/login/login.component';
import { CreateAccountComponent } from './components/session/create-account/create-account.component';
import { PrivacyPolicyComponent } from './components/information/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/information/terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './components/information/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { AuthService } from './services/auth.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';


export function parentRoute(path: string, children: Route[], pathMatch: 'prefix' | 'full' = 'prefix'): Route {
    return {
        path,
        pathMatch,
        children
    };
}

export function route(path: string, component: any, requireAuth: boolean, pathMatch: 'prefix' | 'full' = 'prefix'): Route {
    return {
        path,
        component,
        pathMatch,
        canActivate: [AuthService],
        data: {
            requireAuth
        }
    };
}


const routes: Routes = [
    route('home', HomeComponent, false),
    route('faq', FaqComponent, undefined),
    route('terms-and-conditions', TermsAndConditionsComponent, undefined),
    route('privacy-policy', PrivacyPolicyComponent, undefined),
    parentRoute('session', [
        route('create-account', CreateAccountComponent, false),
        route('login', LoginComponent, false),
        parentRoute('recovery', [
            route('', RecoverPasswordComponent, false, 'full'),
            route('send', SendRecoveryEmailComponent, false),
        ])
    ]),
    route('dashboard', DashboardComponent, true),
    parentRoute('loans', [
        route('', RequestLoanComponent, true, 'full'),
        route(':id', RequestLoanComponent, true),
    ]),
    parentRoute('notifications', [
        route('', NotificationsListComponent, true, 'full'),
        route(':id', NotificationDetailsComponent, true),
    ]),
    route('profile', ProfileComponent, true),
    { path: '**', redirectTo: '/home' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
