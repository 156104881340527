
<div class="form-card revision-step">
    <span class="card-title">
        Préstamo en revisión
    </span>
    <span class="card-description">
        Necesitamos verificar manualmente algunos de sus datos ingresados, uno de nuestros agentes se pondrá en contacto con usted dentro de las próximas 24 horas.
    </span>
    <img src="/assets/image/scoring-result/warning.png" alt="" class="warning-image">
    <a (click)="update()" class="link update-link">Actualizar</a>
</div>
