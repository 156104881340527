<div class="camera-dialog">
    <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <span class="title">
        Tomar una foto
    </span>
    <div id="webcam-container" class="webcam-container" *ngIf="!snapshot && !error">
        <webcam
            (initError)="onError($event)"
            [width]="maxWidth"
            [height]="480"
            [trigger]="snapshotEmitter.asObservable()"
            (imageCapture)="onCapture($event)"
        ></webcam>
        <div class="snapshot-button-container">
            <button mat-fab class="snapshot-button" color="primary" (click)="snapshotEmitter.emit()">
                <mat-icon>photo_camera</mat-icon>
            </button>
        </div>
    </div>
    <div class="preview" *ngIf="snapshot">
        <img [src]="snapshot" alt="" class="preview-image" [ngStyle]="{ maxWidth: maxWidth + 'px' }">
        <button mat-button class="reset-button" color="primary" (click)="snapshot = undefined">
            Volver a tomar
        </button>
    </div>
    <span class="error-message" *ngIf="error">
        Error al conectar. <span class="link" (click)="error = undefined">Volver a intentar</span><br>{{ error }}
    </span>
    <div class="buttons">
        <button mat-button class="cancel-button" color="primary" (click)="close()">
            Cancelar
        </button>
        <button mat-raised-button class="win2win-button continue-button" color="primary" (click)="close(snapshot)" [disabled]="!snapshot">
            Guardar
        </button>
    </div>
</div>
