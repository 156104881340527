import { Phone } from './../../../models/phone.model';
import { Component, Input, ElementRef, Self, Optional, OnDestroy, DoCheck } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: PhoneInputComponent }
    ],
    host: {
        '[class.floating]': 'shouldLabelFloat',
        '[id]': 'id',
        '[attr.aria-describedby]': 'describedBy',
    }
})
export class PhoneInputComponent implements ControlValueAccessor, MatFormFieldControl<Phone>, OnDestroy, DoCheck {

    formGroup = new FormGroup({
        country: new FormControl('', []),
        area: new FormControl('', []),
        phoneNumber: new FormControl('', []),
    });
    stateChanges = new Subject<void>();
    focused = false;
    errorState = false;
    controlType = 'app-phone-input';
    describedBy = '';
    onChange = (_: any) => {};
    onTouched = () => {};

    get empty() {
        const {value: {country, area, phoneNumber}} = this.formGroup;
        return !country && !area && !phoneNumber;
    }

    get shouldLabelFloat() { return this.focused || !this.empty; }

    @Input()
    id: string;

    @Input()
    get placeholder(): string { return this._placeholder; }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder = '';

    @Input()
    get required(): boolean { return this._required; }
    set required(value: boolean) {
        this._required = value;
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get value(): Phone | null {
        if (this.formGroup.valid && !this.empty) {
        const { value } = this.formGroup;
        return value;
        }
        return null;
    }
    set value(tel: Phone | null) {
        const value = tel || {country: '', area: '', phoneNumber: ''};
        this.formGroup.setValue(value);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = value;
        this._disabled ? this.formGroup.disable() : this.formGroup.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    constructor(
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl) {

        _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
        if (this.focused && !origin) {
            this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
        });

        if (this.ngControl != null) {
        this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this._focusMonitor.stopMonitoring(this._elementRef);
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input')!.focus();
        }
    }

    writeValue(obj: Phone) {
        if (obj !== null && obj !== undefined) {
            this.value = obj;
        }
    }

    registerOnChange(fn: (PhoneInputStructure) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    static ngAcceptInputType_disabled: boolean | string | null | undefined;
    static ngAcceptInputType_required: boolean | string | null | undefined;
}
