import { CameraDialogComponent } from './../../../../dialogs/camera-dialog/camera-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Loan } from './../../../../models/loan.model';
import { UtilsService } from './../../../../services/utils.service';
import { ErrorResponse } from './../../../../models/error.model';
import { LoanService } from './../../../../services/loan.service';
import { SelectOption } from './../../../../models/select-option.model';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentType } from 'src/app/models/document.model';
import { rejects } from 'assert';

@Component({
    selector: 'app-request-loan-documentation-step',
    templateUrl: './request-loan-documentation-step.component.html',
    styleUrls: ['./request-loan-documentation-step.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequestLoanDocumentationStepComponent implements OnInit {

    documentationFG = new FormGroup({
        idFront: new FormControl('', [Validators.required]),
        idBack: new FormControl('', [Validators.required]),
        incomeType: new FormControl('RELACION_DE_DEPENDENCIA', [Validators.required]),
        proofOfFounds: new FormControl('', [Validators.required]),
        selfie: new FormControl('', [Validators.required])
    });

    incomeTypes: SelectOption[] = [
        { code: 'RELACION_DE_DEPENDENCIA', description: 'Relación de dependencia' },
        { code: 'MONOTRIBUTISTA', description: 'Monotributista' },
        { code: 'AUTONOMO', description: 'Autónomo' },
    ];

    loanValue?: Loan;
    @Input()
    set loan(loan: Loan) {
        // TODO Completar form
        if (!loan || loan.state !== 'documentation') {
            this.documentationFG.disable();
        } else {
            this.documentationFG.enable();
        }
        this.loanValue = loan;
    }
    get loan() {
        return this.loanValue;
    }

    @Output()
    loanChange = new EventEmitter<Loan>();

    @Output()
    next = new EventEmitter<void>();

    uploadingDocuments = 0;
    errorUploading = false;

    constructor(
        private loanService: LoanService,
        private dialog: MatDialog,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
    }

    sendDocumentation() {
        this.documentationFG.markAllAsTouched();
        if (this.documentationFG.valid) {
            const documentsToUpload: { name: DocumentType, file: File }[] = [
                { name: 'id_front', file: this.documentationFG.value.idFront },
                { name: 'id_back', file: this.documentationFG.value.idBack },
                { name: 'proof_of_funds', file: this.documentationFG.value.proofOfFounds },
                { name: 'selfie', file: this.documentationFG.value.selfie },
            ];
            this.uploadingDocuments = documentsToUpload.length;
            this.errorUploading = false;
            let promises = [];

            documentsToUpload.forEach((doc) => {
                promises.push(
                    new Promise((resolve, reject) => {
                        this.loanService.uploadDocument(this.loan.id, doc.file, doc.name).then(_ => {
                            this.uploadingDocuments -= 1;
                            this.tryToContinue();
                        }, (error: ErrorResponse) => {
                            this.uploadingDocuments -= 1;
                            this.errorUploading = true;
                            resolve({ error: error, file: doc.name })
                        });
                    })
                )
            });

            Promise.all(promises).then((rejected: { error: ErrorResponse<Error>; file: DocumentType; }[]) => {
                const failedFiles = rejected.map(rejected => {return rejected.file})

                if (rejected.length > 1) {
                    this.utilsService.alert('Error al subir archivos', `Hubo un error con los siguientes archivos: ${failedFiles.join(", ")}.`);
                
                } else if (rejected.length === 1) {
                    this.utilsService.alert('Error al subir archivo', `Hubo un error con el siguiente archivo: ${failedFiles[0]}.`);
                }
            })
        }
    }

    showFileErrors() {

    }

    updateDocumentValue(document: string, event: Event) {
        const input = event.target as HTMLInputElement;
        const control = this.documentationFG.get(document);
        control.setValue(input.files[0] ?? '');
        control.markAsDirty();
        control.updateValueAndValidity();
    }

    tryToContinue() {
        if (this.uploadingDocuments === 0 && !this.errorUploading) {
            this.loanService.setIncomeType(this.loan.id, this.documentationFG.value.incomeType).subscribe(loan => {
                this.loanChange.emit(loan);
                this.next.emit();
            }, (error: ErrorResponse) => this.utilsService.alert('Error al enviar documentación', error.error?.message));
        }
    }

    openCameraModal(document: string) {
        this.dialog.open(CameraDialogComponent).afterClosed().subscribe(result => {
            if (result) {
                const file = this.utilsService.dataURLtoFile(result, 'Snapshot.jpg');
                const control = this.documentationFG.get(document);
                control.setValue(file);
                control.markAsDirty();
                control.updateValueAndValidity();
            }
        });
    }

    openHintModal() {
        this.utilsService.alert('Cómo tomarse una selfie con DNI', '<img src="/assets/image/request-loan/selfie_with_dni.jpg" alt="selfie" >', 'selfie-hint-dialog');
    }
}
