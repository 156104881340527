import { ConfigurationService } from './../../../../services/configuration.service';
import { FeesListDialogComponent } from './../../../../dialogs/fees-list-dialog/fees-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Plan } from './../../../../models/plan.model';
import { UtilsService } from './../../../../services/utils.service';
import { LoanService } from './../../../../services/loan.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Loan } from '../../../../models/loan.model';
import { ErrorResponse } from '../../../../models/error.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-request-loan-details-step',
    templateUrl: './request-loan-details-step.component.html',
    styleUrls: ['./request-loan-details-step.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequestLoanDetailsStepComponent implements OnInit {

    loanValue?: Loan;
    @Input()
    set loan(loan: Loan) {
        this.loanValue = loan;
        if (!loan || loan.state !== 'plan') {
            this.detailsFG.disable();
        } else {
            this.detailsFG.enable();
        }
        if (loan.requestedAmount) {
            this.detailsFG.controls.ammount.setValue(loan.requestedAmount);
        }
        if (loan.term) {
            this.detailsFG.controls.term.setValue(loan.term);
        }
        this.maxAmmount = loan?.scoring?.offeredAmount;
        if (!this.detailsFG.value.ammount) {
            this.detailsFG.controls.ammount.setValue(this.maxAmmount);
        }
        this.getPlan(this.detailsFG.value.ammount, this.detailsFG.value.term);
    }
    get loan() {
        return this.loanValue;
    }

    @Output()
    loanChange = new EventEmitter<Loan>();

    @Output()
    next = new EventEmitter<void>();

    detailsFG = new FormGroup({
        ammount: new FormControl('', [Validators.required]),
        term: new FormControl('', [Validators.required])
    });

    plan: Plan;
    minAmmount: number;
    maxAmmount: number;
    availableTerms?: number[];

    constructor(
        private loanService: LoanService,
        private configurationsService: ConfigurationService,
        private dialog: MatDialog,
        public utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.getConfigurations();
        this.detailsFG.controls.ammount.valueChanges.subscribe(newValue => {
            this.getPlan(newValue, this.detailsFG.value.term);
        });
        this.detailsFG.controls.term.valueChanges.subscribe(newValue => {
            this.getPlan(this.detailsFG.value.ammount, newValue);
        });
    }

    getConfigurations() {
        this.configurationsService.getConfigurations().subscribe(configurations => {
            const min = configurations.find(config => config.key === 'PRESTAMO_MONTO_MINIMO')?.value;
            if (min) {
                this.minAmmount = parseInt(min, 10);
            }
            this.availableTerms = configurations
                .find(config => config.key === 'PRESTAMO_PLAZOS')
                ?.value
                ?.split(',')
                ?.map(term => parseInt(term.trim(), 10));
            if (this.availableTerms?.length && !this.detailsFG.value.term) {
                this.detailsFG.controls.term.setValue(this.availableTerms[0]);
                this.getPlan(this.detailsFG.value.ammount, this.detailsFG.value.term);
            }
        });
    }

    getPlan(ammount: number, term: number) {
        if (this.loan && (this.detailsFG.valid)) {
            this.loanService.getPlanSimulation(this.loan?.id, ammount, term).subscribe(plan => {
                this.plan = plan;
            }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener detalles del plan', error.error?.message));
        } else if (this.loan && this.detailsFG.disabled) {
            this.loanService.getPlan(this.loan?.id).subscribe(plan => {
                this.plan = plan;
            }); // No alert on error
        }
    }

    openFeesModal() {
        if (this.plan?.fees) {
            this.dialog.open(FeesListDialogComponent, { data: { fees: this.plan.fees } });
        }
    }

    continue() {
        if (this.detailsFG.valid) {
            this.loanService.confirmLoan(this.loan.id, this.detailsFG.value.ammount, this.detailsFG.value.term).subscribe(loan => {
                this.loanChange.emit(loan);
                this.next.emit();
            }, (error: ErrorResponse) => this.utilsService.alert('Error al confirmar crédito', error.error?.message));
        }
    }

    getTodayDate() {
        return this.utilsService.beautifyDate(undefined, 'L');
    }

    formatLabel = (ammount: number) => {
        return `${ ammount / 1000 }k`;
    }
}
