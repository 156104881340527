
<form class="form-card scoring-step" [formGroup]="personalDataFG" (submit)="sendScoring()">
    <span class="card-title">
        Datos personales
    </span>
    <span class="card-description">
        Por favor ingresá tu numero de CUIT o CUIL.
    </span>
    <mat-form-field appearance="outline" class="light-input win2win-form-field">
        <mat-label>CUIT - CUIL</mat-label>
        <input matInput formControlName="document" type="text" mask="00-00000000-0">
        <mat-error *ngIf="personalDataFG.controls.document.hasError('required')">
            Por favor ingrese su CUIT o CUIL
        </mat-error>
        <mat-error *ngIf="personalDataFG.controls.document.hasError('minlength')">
            Por favor ingrese los 11 digitos de su CUIT o CUIL
        </mat-error>
        <mat-error *ngIf="personalDataFG.controls.document.hasError('maxlength')">
            Maximo de caracteres excedido
        </mat-error>
    </mat-form-field>
    <app-sex-selector class="sex-selector-container" formControlName="sex"></app-sex-selector>
    <div class="buttons">
        <button mat-raised-button class="continue-button win2win-button" [disabled]="personalDataFG.disabled">
            Continuar
        </button>
    </div>
</form>
