<app-logged-navbar>
    <div class="profile">
        <span class="page-title">
            Perfil
        </span>
        <mat-tab-group mat-stretch-tabs class="tabs stretch-tabs">
            <mat-tab label="Información general">
                <span class="section-title">
                    Información general
                </span>
                <form class="form general-information" [formGroup]="generalInformationFG" (ngSubmit)="saveGeneralInformation()">
                    <div class="form-line">
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Nombre</mat-label>
                            <input matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Edad</mat-label>
                            <input matInput formControlName="age" type="number">
                        </mat-form-field>
                    </div>
                    <div class="form-line">
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>CUIT</mat-label>
                            <input matInput formControlName="cuit">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input matInput formControlName="birth" [matDatepicker]="birthPicker">
                            <mat-datepicker-toggle matSuffix [for]="birthPicker"></mat-datepicker-toggle>
                            <mat-datepicker #birthPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-line">
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Provincia</mat-label>
                            <input matInput formControlName="province">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Ciudad</mat-label>
                            <input matInput formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="form-line">
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Dirección</mat-label>
                            <input matInput formControlName="address">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Código postal</mat-label>
                            <input matInput formControlName="postalCode">
                        </mat-form-field>
                    </div>
                    <div class="buttons">
                        <button mat-raised-button class="save-button win2win-button mini">
                            Guardar
                        </button>
                    </div>
                </form>
            </mat-tab>
            <mat-tab label="Seguridad de cuenta">
                <span class="section-title">
                    Seguridad de cuenta
                </span>
                <div class="form security">
                    <form class="change-password" [formGroup]="changePasswordFG" (ngSubmit)="changePassword()">
                        <mat-form-field appearance="outline" class="light-input win2win-form-field password-form-field">
                            <mat-label>Cambiar contraseña</mat-label>
                            <input matInput formControlName="password" type="password">
                            <mat-hint>Debe tener al menos una mayúscula, una minúscula y un número</mat-hint>
                            <mat-error *ngIf="changePasswordFG.controls.password.hasError('required')">
                                Por favor complete su contraseña
                            </mat-error>
                            <mat-error *ngIf="changePasswordFG.controls.password.hasError('noLowerCase')">
                                La contraseña debe tener una letra minúscula
                            </mat-error>
                            <mat-error *ngIf="changePasswordFG.controls.password.hasError('noUpperCase')">
                                La contraseña debe tener una letra mayúscula
                            </mat-error>
                            <mat-error *ngIf="changePasswordFG.controls.password.hasError('noNumber')">
                                La contraseña debe tener un número
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="light-input win2win-form-field">
                            <mat-label>Confirmar contraseña</mat-label>
                            <input matInput formControlName="passwordConfirm" type="password">
                            <mat-error *ngIf="changePasswordFG.controls.passwordConfirm.hasError('required')">
                                Por favor vuelva a ingresar su contraseña
                            </mat-error>
                            <mat-error *ngIf="changePasswordFG.controls.passwordConfirm.hasError('notEquals')">
                                Las contraseñas no son iguales
                            </mat-error>
                        </mat-form-field>
                        <button mat-raised-button class="save-button win2win-button mini">
                            Cambiar contraseña
                        </button>
                    </form>
                    <button mat-raised-button class="logout-button win2win-button" color="accent" (click)="logout()">
                        Cerrar sesión
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</app-logged-navbar>
