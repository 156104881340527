import { ErrorResponse } from './../../../../models/error.model';
import { UtilsService } from './../../../../services/utils.service';
import { LoanService } from './../../../../services/loan.service';
import { Loan } from '../../../../models/loan.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-request-loan-scoring-step',
    templateUrl: './request-loan-scoring-step.component.html',
    styleUrls: ['./request-loan-scoring-step.component.scss']
})
export class RequestLoanScoringStepComponent implements OnInit {

    personalDataFG = new FormGroup({
        document: new FormControl('', [Validators.required, Validators.maxLength(11), Validators.minLength(11)]),
        sex: new FormControl('', [Validators.required]),
    }); 

    @Input()
    set loan(loan: Loan) {
        // TODO Completar form
        if (loan) {
            this.personalDataFG.disable();
        }
    }

    @Output()
    loanChange = new EventEmitter<Loan>();

    @Output()
    next = new EventEmitter<void>();

    constructor(
        private loanService: LoanService,
        private utilsService: UtilsService,
        private location: Location,
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    mask = "00-00000000-0";

    sendScoring() {
        console.log(this.personalDataFG.controls.document.errors)
        if (this.personalDataFG.valid) {
            this.loanService.createLoan(String(this.personalDataFG.value.document), this.personalDataFG.value.sex).subscribe(loan => {
                if (this.router.url.endsWith('/loans')) {
                    this.location.go(this.router.url + '/' + loan.id);
                } else if (this.router.url.endsWith('/loans/')) {
                    this.location.go(this.router.url + loan.id);
                }
                this.loanChange.emit(loan);
                this.next.emit();
            }, (error: ErrorResponse) => this.utilsService.alert('Error al comenzar crédito', error.error?.message));
        }
    }

}
