<app-logged-navbar>
    <div class="notification-details" *ngIf="notification">
        <span class="page-title">
            Detalles de la notificación
        </span>
        <div class="notification">
            <span class="notification-title">{{ notification.title }}</span>
            <span class="notification-content">{{ notification.content }}</span>
            <span class="notification-date">{{ utilsService.beautifyDate(notification.added, 'L HH:mm') }}</span>
        </div>
    </div>
</app-logged-navbar>
