import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../services/utils.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarButton } from '../../navbar/unlogged-navbar/unlogged-navbar.component';
import { AnyDict } from '../../../models/generics.model';

@Component({
    selector: 'app-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

    navbarLeftButtons: NavbarButton[] = [
        { label: 'Preguntas frecuentes', onClick: () => this.router.navigate(['faq']) },
    ];

    navbarRightButtons: NavbarButton[] = [
        { label: 'Iniciar sesión', onClick: () => this.router.navigate(['session', 'login']) },
        { label: 'Crear cuenta', onClick: () => this.router.navigate(['session', 'create-account']), raised: true }
    ];

    recoveryFG = new FormGroup({
        password: new FormControl('', [Validators.required, (control: AbstractControl): AnyDict | null => {
            if (!control) return null;
            if (!/[A-Z]/.test(control.value)) {
                return { noUpperCase: true };
            }
            if (!/[a-z]/.test(control.value)) {
                return { noLowerCase: true };
            }
            if (!/\d/.test(control.value)) {
                return { noNumber: true };
            }
            return null;
        }]),
        passwordConfirm: new FormControl('', [Validators.required, (control: AbstractControl): AnyDict | null => {
            if (control && this.recoveryFG && control.value === this.recoveryFG.value.password) {
                return null;
            }
            return { notEquals: true };
        }])
    });

    error: string;

    email: string;
    token: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.email = params.email;
            this.token = params.token;
        });
    }

    recoverPassword() {
        if (this.recoveryFG.valid) {
            this.error = undefined;
            this.authService.recoverAccount(this.token, this.email, this.recoveryFG.value.password).subscribe(session => {
                this.utilsService.alert('Contraseña cambiada correctamente', 'Ya puede ingresar a su cuenta.')
                    .afterClosed().subscribe(_ => {
                        this.router.navigate(['session', 'login']);
                    });
            }, error => this.error = error.error.message);
        }
    }
}
