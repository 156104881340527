
<div class="form-card done-step">
    <span class="card-title">
        ¡Ya tenés tu crédito!
    </span>
    <span class="card-description">
        Dentro de las próximas 24 horas estará recibiendo el depósito en su cuenta.
    </span>
    <img src="/assets/image/scoring-result/approved.png" alt="" class="approved-image">
    <div class="buttons">
        <button mat-raised-button class="continue-button win2win-button" routerLink="/">
            Volver al dashboard
        </button>
    </div>
</div>
