import { NavbarButton } from './../unlogged-navbar/unlogged-navbar.component';
import { NotificationService } from './../../../services/notification.service';
import { Notification } from './../../../models/notification.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from './../../../services/utils.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ErrorResponse } from '../../../models/error.model';

@Component({
    selector: 'app-logged-navbar',
    templateUrl: './logged-navbar.component.html',
    styleUrls: ['./logged-navbar.component.scss']
})
export class LoggedNavbarComponent implements OnInit, OnDestroy {

    menuOpen = false;

    notifications: Notification[] = [];
    unreadNotificationsCount = 0;

    interval: any;

    @Input()
    withBackground?: boolean;

    constructor(
        public utilsService: UtilsService,
        private authService: AuthService,
        private notificationService: NotificationService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.updateNotificationCount();
        this.interval = setInterval(() => this.updateNotificationCount(), 15000);
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    getMenuButtons(): NavbarButton[] {
        return [
            { label: 'Volver al inicio', onClick: () => this.router.navigate(['dashboard']), raised: false },
            { label: 'Notificaciones', onClick: () => this.router.navigate(['notifications']), raised: false },
            { label: 'Perfil', onClick: () => this.router.navigate(['profile']), raised: false },
            { label: 'Cerrar sesión', onClick: () => this.logout(), raised: true },
        ];
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['home']);
    }

    getNotifications() {
        this.notificationService.getNotifications(0, 4, {}).subscribe(notifications => {
            this.notifications = notifications.body;
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener notificaciones', error.error?.message));
    }

    updateNotificationCount() {
        this.notificationService.getNotificationsCount().subscribe(count => {
            this.unreadNotificationsCount = count;
        }, (error: ErrorResponse) => {});
    }
}
