import { ErrorResponse } from './../../../models/error.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarButton } from '../../navbar/unlogged-navbar/unlogged-navbar.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    navbarLeftButtons: NavbarButton[] = [
    ];

    navbarRightButtons: NavbarButton[] = [
        { label: 'Preguntas frecuentes', onClick: () => this.router.navigate(['faq']) },
        { label: 'Crear cuenta', onClick: () => this.router.navigate(['session', 'create-account']), raised: true }
    ];

    loginFG = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required])
    });

    error: string;

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
    }

    login() {
        if (this.loginFG.valid) {
            this.error = undefined;
            this.authService.login(this.loginFG.value.email, this.loginFG.value.password).subscribe(session => {
                this.authService.saveToken(session.token);
                if (session.user)
                    this.authService.saveUser(session.user);
                this.router.navigate(['dashboard']);
            }, (error: ErrorResponse) => this.error = error.error.message);
        }
    }

}
