
<form class="form-card details-step" [formGroup]="detailsFG" (submit)="continue()">
    <span class="card-title">
        Detalles de tu crédito
    </span>
    <span class="card-description">
        Por favor verifique los datos y confirme su crédito.
    </span>
    <div class="ammount-selector" *ngIf="minAmmount && maxAmmount">
        <span class="ammount-selector-label">
            Capital a solicitar
        </span>
        <div class="slider-container">
            <span class="min-label">${{ utilsService.beautifyNumber(minAmmount, 0) }}</span>
            <mat-slider
                thumbLabel
                [displayWith]="formatLabel"
                step="1000"
                [min]="minAmmount"
                [max]="maxAmmount"
                color="primary"
                formControlName="ammount"
            ></mat-slider>
            <span class="max-label">${{ utilsService.beautifyNumber(maxAmmount, 0) }}</span>
        </div>
    </div>
    <div class="details" *ngIf="plan">
        <div class="details-left">
            <div class="detail ammount-detail">
                <span class="detail-label">Capital solicitado</span>
                <span class="detail-value">${{ utilsService.beautifyNumber(detailsFG.value.ammount, 0) }}</span>
            </div>
            <div class="detail">
                <span class="detail-label">Fecha de solicitud</span>
                <span class="detail-value">{{ getTodayDate() }}</span>
            </div>
            <mat-form-field class="light-input win2win-form-field" appearance="outline">
                <mat-label>Plazo</mat-label>
                <mat-select formControlName="term">
                    <mat-option *ngFor="let term of availableTerms" [value]="term">
                        {{ term + (term === 1 ? ' cuota' : ' cuotas') }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="vertical-separator"></div>
        <div class="details-center">
            <div class="detail">
                <span class="detail-label">TNA</span>
                <span class="detail-value">{{ utilsService.beautifyNumber(plan.nominalRate * 100, 2) }}%</span>
            </div>
            <div class="detail">
                <span class="detail-label">TEA</span>
                <span class="detail-value">{{ utilsService.beautifyNumber(plan.effectiveAnnualRate * 100, 2) }}%</span>
            </div>
            <div class="detail">
                <span class="detail-label">CFT</span>
                <span class="detail-value">{{ utilsService.beautifyNumber(plan.totalFinancialCost * 100, 2) }}%</span>
            </div>
        </div>
        <div class="vertical-separator"></div>
        <div class="details-right">
            <div class="detail">
                <span class="detail-label">Monto de cuota promedio</span>
                <span class="detail-value">${{ utilsService.beautifyNumber(plan.averageFee, 2) }}</span>
            </div>
            <div class="detail">
                <span class="detail-label">Interés de cuota promedio</span>
                <span class="detail-value">${{ utilsService.beautifyNumber(plan.averageInterest, 2) }}</span>
            </div>
            <div class="detail">
                <span class="detail-label">Fecha del próximo vencimiento</span>
                <span class="detail-value">{{ utilsService.beautifyDate(plan.firstDueDate, 'L') }}</span>
            </div>
        </div>
    </div>
    <button mat-raised-button class="plan-button win2win-button mini" type="button" [disabled]="!plan" (click)="openFeesModal()">
        Ver detalle de cuotas
    </button>
    <div class="buttons">
        <button mat-raised-button class="continue-button win2win-button" [disabled]="detailsFG.disabled">
            Quiero mi crédito
        </button>
    </div>
</form>
