import { MatDialog } from '@angular/material/dialog';
import { LoanState } from './../../../models/loan.model';
import { ErrorResponse } from './../../../models/error.model';
import { LoanService } from './../../../services/loan.service';
import { UtilsService } from './../../../services/utils.service';
import { Loan } from '../../../models/loan.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeesListDialogComponent } from '../../../dialogs/fees-list-dialog/fees-list-dialog.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    loans: Loan[] = [];

    constructor(
        private loanService: LoanService,
        public utilsService: UtilsService,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.loanService.getLoans().subscribe(loans => {
            if (loans.length) {
                this.loans = loans;
            } else {
                this.router.navigate(['loans']);
            }
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener créditos', error.error?.message));
    }

    translateStatus(status: LoanState) {
        if (status === 'documentation') {
            return 'Documentación pendiente';
        } else if (status === 'plan') {
            return 'Aprobación pendiente';
        } else if (status === 'revision') {
            return 'En revisión';
        } else if (status === 'bank_account_info') {
            return 'Información bancaria pendiente';
        } else if (status === 'signature') {
            return 'Firma pendiente';
        } else if (status === 'payment_subscription') {
            return 'Subscripción de pago';
        } else if (status === 'awaiting_transfer') {
            return 'Depósito bancario en trámite';
        } else if (status === 'active') {
            return 'Activo';
        } else if (status === 'rejected') {
            return 'Rechazado';
        }
    }

    getCardButtonText(loan: Loan) {
        if (loan.state === 'active' || loan.state === 'awaiting_transfer') {
            return 'Ver detalles';
        } else if (loan.state === 'rejected') {
            return 'Préstamo rechazado';
        } else {
            return 'Continuar crédito';
        }
    }

    onCardButtonClick(loan: Loan) {
        if (loan.state === 'active' || loan.state === 'awaiting_transfer') {
            this.openLoanDetailsDialog(loan);
        } else {
            this.router.navigate(['loans', loan.id]);
        }
    }

    openLoanDetailsDialog(loan: Loan) {
        this.loanService.getPlan(loan?.id).subscribe(plan => {
            this.dialog.open(FeesListDialogComponent, { data: { fees: plan.fees } });
        }, (error: ErrorResponse) => this.utilsService.alert('Error al obtener detalles del plan', error.error?.message));
    }
}
