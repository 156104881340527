import { Component, OnInit } from '@angular/core';
import { FAQ, InformationService } from 'src/app/services/information.service';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    public questions: FAQ[];

    constructor(private informationService: InformationService) { }

    ngOnInit() {
        this.questions = this.informationService.getFaqs();
        for (const faq of this.questions) {
            faq.show = false;
        }
    }

}
